
import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import '../pages/admin.css';
import { Link } from 'react-router-dom';
import Axios from 'axios';



const Employee_manage = (props) => {

    const localuser = JSON.parse(localStorage.getItem('user'));
    let employee_id = Apiconnect.decrypt(localuser.id); 
    let id = props.match.params.id;
    if (id === '0') {
        id = null;
    }

    const alert = useAlert();
    let history = useHistory();
    const [dobDate, setDobDate] = useState(new Date());


    useEffect(() => { getItem(); getShift(); getEmp(); getTemplate();getEmpType();getEmpGroup();getEmpDesignation(); }, []);
    useEffect(() => {employee_code()})    
    const [empCode, setEmp_code] = useState([]);
    const [catList, setCategory] = useState([]);
    const [shiftList, setShift] = useState([]);
    const [empList, setEmp] = useState([]);
    const [empTypeList, setEmpType] = useState([]);
    const [empGroupList, setEmpGroup] = useState([]);
    const [empDesignationList, setEmpDesignationList] = useState([]);
    const [templateList, setTemplate] = useState([]);

    const employee_code = () => {
        Apiconnect.getData(`employee/getEmployeeCode/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmp_code(_xtract[0].prefix+'/'+_xtract[0].count)
        });
    }

    const getItem = () => {
        if (id > 0) {
            Apiconnect.getData(`employee/get/${id}`).then((response) => {
                let _xtract = Apiconnect.decrypt_obj(response.data.data);
                getCategory(_xtract[0].emp_group);
                setInfo({
                    emp_code: _xtract[0].emp_code,
                    first_name: _xtract[0].first_name,
                    middle_name: _xtract[0].middle_name,
                    last_name: _xtract[0].last_name,
                    phone: _xtract[0].phone,
                    email: _xtract[0].email,
                    password: _xtract[0].password,
                    gender: _xtract[0].gender,
                    date_of_birth: _xtract[0].date_of_birth,
                    emp_category: _xtract[0].emp_category,
                    emp_shift: _xtract[0].emp_shift,
                    reporting_officer: _xtract[0].reporting_officer,
                    id: _xtract[0].id,
                    employment_type_id: _xtract[0].employment_type_id,
                    emp_group:_xtract[0].emp_group,
                    emp_designation:_xtract[0].emp_designation,
                    ctc_yearly: _xtract[0].ctc_yearly,
                    // salary_template_id: _xtract[0].salary_template_id,
                    pm_userId:_xtract[0].pm_userId
                });
                if (_xtract[0].date_of_birth !== null && _xtract[0].date_of_birth !== '0000-00-00') {
                    setDobDate(new Date(_xtract[0].date_of_birth));
                } else {
                    setDobDate(null);
                }

            });
        }
    };
    const getCategory = (emp_group) => {
        Apiconnect.getData(`company_category/getAllByGroupId/${Apiconnect.get_company_id()}/${emp_group}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setCategory(_xtract.reverse());
        });
    }
 
    const getShift = () => {
        Apiconnect.getData(`shift/getall/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setShift(_xtract.reverse());
        });
    }


    const getEmp = () => {
        Apiconnect.getData(`employee/getusers/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            // console.log(_xtract);
            setEmp(_xtract.reverse());
        });
    }


    const getEmpType = () => {
        Apiconnect.getData(`common/getallemploymenttype`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpType(_xtract.reverse());
        });
    }

   
    const getEmpGroup = () => {
        Apiconnect.getData(`common/getallemploymenttypeById/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpGroup(_xtract.reverse());
        });
    }

    const getEmpDesignation = () => {
        Apiconnect.getData(`designation/getall/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = response.data.data;
            setEmpDesignationList(_xtract.reverse());
        });
    }

    const getTemplate = () => {
        Apiconnect.getData(`employee_salary_template/getAllTemplate/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setTemplate(_xtract.reverse());
        });
    }



    const [info, setInfo] = useState({
        emp_code: '', first_name: '', middle_name: '', last_name: '', phone: '', email: '', password: '',
        gender: '', date_of_birth: '', emp_category: '', employment_type_id: '',emp_group:'',emp_designation:'', emp_shift: '',
        company_id: Apiconnect.get_company_id(), reporting_officer: '',pm_userId:''
    });
    const { emp_code, first_name, middle_name, last_name, phone, email, password,
        gender, date_of_birth, emp_category, employment_type_id,emp_group,emp_designation, emp_shift, reporting_officer, pm_userId } = info;

    const onInputChange = e => {
        
        if (e.target.name === 'emp_group') {
            getCategory(e.target.value);
        }
        
        if (e.target.name === 'emp_code') {
            setNumMsg({ msg: '' });
        }

        if (e.target.name === 'phone') {
            if (e.target.value.length > 10) {
                setNumMsg2({ msg2: '' });
                return false;
            } else {
                setNumMsg2({ msg2: 'Number Should 10 Digits' });
            }
        }

        setInfo({ ...info, [e.target.name]: e.target.value });
    };

    const [num_msg2, setNumMsg2] = useState({ msg2: '' });
    const { msg2 } = num_msg2;

    const [num_msg, setNumMsg] = useState({ msg: '' });
    const { msg } = num_msg;


    const onSubmit = async e => {
        e.preventDefault();

        if (id > 0) {
            // console.log('in edit function' + id);
            // console.log(id);
            // console.log(info.ctc_yearly);
            // console.log(info.salary_template_id);

            // Apiconnect.getData(`employee_salary/add_salaryfrom_template/${info.salary_template_id}/${info.ctc_yearly}/${id}`).then((response) => {
            //     let _xtract = response.data;
            // });


            var today = new Date();
            var birthDate = new Date(dobDate);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            if (age <= 18) {
                alert.success('Employee age should be greater than 18 years');
                return false;
            }   

            info.date_of_birth = format(dobDate, 'yyyy-MM-dd');
            let info_enc = Apiconnect.encrypt_obj(info);
            const inf_a = { enc: info_enc,employee_id:employee_id };
            await Apiconnect.postData("employee/update/", inf_a).then((response) => {
                setInfo({
                    emp_code: '', first_name: '', middle_name: '', last_name: '', phone: '', email: '', password: '',
                    gender: '', date_of_birth: '', emp_category: '', employment_type_id: '', emp_group:'',emp_designation:'', id: '', company_id: Apiconnect.get_company_id(), emp_shift: '', reporting_officer: ''
                });
                alert.success(response.data.message);
                history.push(`/employee_manage_info/${id}`);
            });

        } else {
            console.log('in add function.....');

            var today = new Date();
            var birthDate = new Date(dobDate);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            if (age <= 18) {
                alert.success('Employee age should be greater than 18 years');
                return false;
            }

            // console.log(info);
            // return false;
            info.date_of_birth = format(dobDate, 'yyyy-MM-dd');
            info.emp_code = empCode;
            let info_enc = Apiconnect.encrypt_obj(info);
            const inf_a = { enc: info_enc,employee_id:employee_id  };

            await Apiconnect.postData("employee/create", inf_a).then((response) => {
            // Axios.post(`http://localhost:3050/api/tush/create`,inf_a).then((response) => {

                // var last_id = Apiconnect.encrypt(response.data.id);
                var last_id = response.data.id;
                if (typeof response.data.error !== 'undefined') {
                    if (response.data.error.code === 'ER_DUP_ENTRY') {
                        alert.success('Employee Code Already Exist');
                        setNumMsg({ msg: 'Employee Code Already Exist' });
                    } else {

                        //////SALARY ADD PROCESS----
                        // Apiconnect.getData(`employee_salary/add_salaryfrom_template/${info.salary_template_id}/${info.ctc_yearly}/${last_id}`).then((response) => {
                        //     let _xtract = response.data;
                        
                        // });
                        //////SALARY ADD PROCESS ENDS----




                        alert.success(response.data.message);
                        setInfo({
                            emp_code: '', first_name: '', middle_name: '', last_name: '', phone: '', email: '', password: '',
                            gender: '', date_of_birth: '', emp_category: '', employment_type_id: '',emp_group:'',emp_designation:'', id: '', company_id: Apiconnect.get_company_id(), emp_shift: '', reporting_officer: ''
                        });
                        history.push(`/employee_manage_info/${last_id}`);
                    }
                } else {
                    alert.success(response.data.message);
                    setInfo({
                        emp_code: '', first_name: '', middle_name: '', last_name: '', phone: '', email: '', password: '',
                        gender: '', date_of_birth: '', emp_category: '', employment_type_id: '',emp_group:'',emp_designation:'', id: '', company_id: Apiconnect.get_company_id(), emp_shift: '', reporting_officer: ''
                    });
                    history.push(`/employee_manage_info/${last_id}`);
                }
            });



        }
    };




    function refreshPage() {
        window.location.reload();
    }
    return (

        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                            <h4 className="card-title">Employee Manage <span style={{ fontSize: '15px', color: 'blue' }}>Stage 1 to 5</span></h4>
                            {/* <h6 style={{padding:'5px'}}>Stage 1 to 5</h6> */}
                        </div>
                    </div>
                    <div className="iq-card-body">
                        {id !== null ?
                            <div className="bs-example">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link active" data-toggle="tab" to={`/employee_manage/${id}`} >Personal</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_info/${id}`} >Other Details</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_bank/${id}`} >Financial</Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_employement/${id}`} >Employment Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_reimbursement/${id}`} >Reimbursement Info</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_sal/${id}`} >Salary (Monthly Wages)</Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_qualification/${id}`} >Educational Qualification</Link>
                                    </li>
                                </ul>
                            </div>
                            : ''}

                        <form onSubmit={e => onSubmit(e)}>
                            <div className="border" >
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Employee Code</label>
                                            <input readonly="readonly" type="text" className="form-control " name="emp_code" value={emp_code ? emp_code : empCode} placeholder="Employee Code" onChange={e => onInputChange(e)}  />
                                            {/* <span className="required">{msg}</span> */}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Reporting Officer <span className="required">*</span></label>
                                            <select className="form-control" required name="reporting_officer" value={reporting_officer} onChange={e => onInputChange(e)}>
                                                <option value="">Select Reporting Officer</option>
                                                {
                                                    empList.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Salary Template</label>
                                            <select className="form-control" name="salary_template_id" value={salary_template_id} onChange={e => onInputChange(e)}>
                                                <option value="">Select Template</option>
                                                {
                                                    templateList.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Yearly CTC </label>
                                            <input type="text" className="form-control " name="ctc_yearly" value={ctc_yearly} placeholder="Yearly CTC" onChange={e => onInputChange(e)} />

                                        </div>
                                    </div> */}



                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>First Name <span className="required">*</span></label>
                                            <input type="text" className="form-control " name="first_name" value={first_name} placeholder="First Name" onChange={e => onInputChange(e)} required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Middle Name   </label>
                                            <input type="text" className="form-control " name="middle_name" value={middle_name} placeholder="Middle Name" onChange={e => onInputChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Last Name <span className="required">*</span></label>
                                            <input type="text" className="form-control " name="last_name" value={last_name} placeholder="Last Name" onChange={e => onInputChange(e)} required />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Phone <span className="required">*</span></label>
                                            <input type="text" className="form-control " name="phone" value={phone} placeholder="Phone" onChange={e => onInputChange(e)} required />
                                            <span className="required">{msg2}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email <span className="required">*</span></label>
                                            <input type="email" className="form-control " name="email" value={email} placeholder="Email" onChange={e => onInputChange(e)} required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="form-group">
                                            <label>Designation <span className="required">*</span></label>
                                            <select className="form-control" required name="emp_designation" value={emp_designation} onChange={e => onInputChange(e)}>
                                                <option value="">Select Designation</option>
                                                {
                                                    empDesignationList.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {/* <div className="form-group">
                                            <label>Password <span className="required">*</span></label>
                                            <input type="text" className="form-control " name="password" value={password} placeholder="Password" onChange={e => onInputChange(e)} required />
                                        </div> */}
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Gender <span className="required">*</span></label>
                                            <select className="form-control" name="gender" required value={gender} onChange={e => onInputChange(e)}>
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                            {/* <input type="text" className="form-control " name="gender" value={gender} placeholder="Gender" onChange={e => onInputChange(e)} /> */}

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Date Of Birth <span className="required">*</span></label>
                                            <DatePicker
                                                selected={dobDate}
                                                onChange={e => setDobDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="Date Of Birth"
                                                name="date_of_birth"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Employee Shift <span className="required">*</span></label>
                                            <select className="form-control" required name="emp_shift" value={emp_shift} onChange={e => onInputChange(e)}>
                                                <option value="">Select Shift</option>
                                                {
                                                    shiftList.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">

                                <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Employment Group <span className="required">*</span></label>
                                            <select className="form-control" required name="emp_group" value={emp_group} onChange={e => onInputChange(e)}>
                                                <option value="">Select Group</option>
                                                {
                                                    empGroupList.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Employee Department <span className="required">*</span></label>
                                            <select className="form-control" required name="emp_category" value={emp_category} onChange={e => onInputChange(e)}>
                                                <option value="">Select Department</option>
                                                {
                                                    catList.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Employment Type <span className="required">*</span></label>
                                            <select className="form-control" required name="employment_type_id" value={employment_type_id} onChange={e => onInputChange(e)}>
                                                <option value="">Select Type</option>
                                                {
                                                    empTypeList.map((val, key) => {
                                                        return (
                                                            <option key={key} value={val.id}>{val.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                

                                </div>
                                <div className="row">
                                     
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>RF ID</label>
                                            <input type="text" className="form-control " name="pm_userId" value={pm_userId} placeholder="RF Id" onChange={e => onInputChange(e)} />
                                        </div>
                                    </div>           
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <button type="submit" name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    <button type="button" className="btn iq-bg-danger" onClick={refreshPage}> Cancel </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )


}
export default Employee_manage