import React, { useState, useEffect, Component } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../services/Apiconnect.js";
// import "./ems_pages.css";
import moment from 'moment'
import { format } from 'date-fns';
import * as html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import Axios from 'axios';

function Salaryslip_pdf(props) {
    let history = useHistory();

    let emp_id = props.match.params.id;
    let year = props.match.params.year;
    let month = props.match.params.month;
    let months = Number(month) - 1;

   const local_user = JSON.parse(localStorage.getItem('user'));
    // function returnPage() {
    //     history.push("/ems/salary_slip");
    // }

    const [salaryMonth, setSalaryMonthList] = useState([]);
    const [deduction_tot, setDeduction_tot] = useState(0);
    const [earning_tot, setEarning_tot] = useState(0);
    const [leavestypelist, setleavestypelist] = useState([]);
    const [debited_leaves, setdebited_leaves] = useState(0);
    const [paidDay, setPaidDay] = useState(0);

    useEffect(() => { getsalaryMonthWise();getEmpInfo();getLeaveByType();getMonthlyPaidDay() }, []);
    const [info, setInfo] = useState({
        name: '', department: '', emp_code: '', account_number: '', bank_name: '', pan: '',esi_number:'',epf_number:''
    });
    const { name, department, emp_code, account_number, bank_name, pan,epf_number,esi_number } = info;

    const getEmpInfo = () =>{
        Apiconnect.getData(`employee_monthly_salary/getempsalaryinfo/${emp_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);

           if(_xtract.length > 0){
                setInfo({
                    name: _xtract[0].first_name + ' ' + _xtract[0].last_name, department: _xtract[0].department, emp_code: _xtract[0].emp_code,
                    account_number: _xtract[0].account_number, bank_name: _xtract[0].bank_name, pan: _xtract[0].pan,esi_number:_xtract[0].esi_number,
                    epf_number:_xtract[0].epf_number
                });
           }else{

            console.log('error');
           }
           
        });
    }

    const getMonthlyPaidDay = () => {
        Apiconnect.getData(`employee_leave/getmonthlypaiddays/${emp_id}/${year}/${month}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            console.log('paidDays',_xtract)
            setPaidDay(_xtract[0].present_days)

        });
    }

    const getsalaryMonthWise = () => {
        const info = {};
        info.month = month;
        info.year = year;
        info.emp_id = emp_id;
         let info_enc =  Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
        console.log(info);
         Apiconnect.postData(`public/getsalarymonthwisepdf`,inf_a).then((response) => {
          //  Axios.post(`http://localhost:3050/api/public/getsalarymonthwisepdf`,inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            console.log(_xtract);
            if(_xtract.length <= 0){
            }else{
                setEarning_tot(_xtract[0].earning_tot);
                setDeduction_tot(_xtract[0].deduction_tot);
                console.log(_xtract);
                setSalaryMonthList(_xtract.reverse());
                generatePDF();
            }
           
        });
    };

    const mn = [
        'JAN', 'FEB', 'MAR', 'APR',
        'MAY', 'JUN', 'JUL', 'AUG',
        'SEP', 'OCT', 'NOV', 'DEC'
    ]
    
    const generatePDF = e => {

        var doc = new jsPDF('l', 'pt','a3');
      
        doc.html(document.getElementById('print'), {
        callback: function (doc) {
            doc.save(name+' payslip_'+mn[months]+'.pdf');
        },
        x:70,
        y: 10,
        });
    }

    const getLeaveByType = () => {
        Apiconnect.getData(`employee_leave/getAllLeaveByType/${emp_id}`).then(
          (response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setleavestypelist(_xtract.reverse());
          }
        );

        Apiconnect.getData(`employee_leave/totaltakenleave/${emp_id}`).then(
            (response) => {
              let _xtract = Apiconnect.decrypt_obj(response.data.data);
              if (_xtract[0].debitleaves !== null) {
                setdebited_leaves(_xtract[0].debitleaves);
              }
            }
          );
      };

    var year1 = new Date().getFullYear();
    return (
        <>
                        <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-body"  >
                                <div className="col-md-12 m-t-20" style={{paddingBottom:'30px'}}>
                                        <button  className="btn btn-success mr-2" onClick={e=>generatePDF(e)} style={{cursor: 'pointer',float: 'right'}}><i className="fa fa-download" aria-hidden="true" ></i> Download</button>
                                    </div>
                                    <div className="container mt-5 mb-5"id="print" style={{'backgroundColor': 'unset'}}>
                                        <div className="row" style={{ border: '1px solid black' }}>
                                            <div className="col-sm-12 col-lg-12" style={{ backgroundColor: '#c2d69b', marginBottom: '10px' }}>
                                                <b> {local_user.company_name}</b>
                                                <div className="text-center lh-1 mb-2">
                                                    <h6 className="fw-bold">Payslip</h6> <span className="fw-normal">Payment slip for the month of <b>{mn[months]} {year}</b></span>
                                                </div>
                                            </div>

                                            <div className="col-md-12">

                                                {/* <div className="d-flex justify-content-end"> <span>Working Branch:ROHINI</span> </div> */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                        <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>EMP Name :</b> </span>{name} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Bank Name :</b> </span> {bank_name} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>ESI No. :</b> </span> {esi_number} </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>EMP Code :</b> </span> {emp_code} </div>
                                                            </div>
                                                           
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Bank A/C no. :</b> </span> {account_number} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>PF No. :</b> </span> {epf_number} </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Employee Group :</b> </span> {department} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Branch Name :</b> </span>  </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Payslip no :</b> </span>  </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>

                                                    <div className="col-md-12" style={{marginTop:'10px'}}>
                                                    <div className="col-md-7" style={{paddingLeft:'0px'}}>
                                                            <div><b>Total Paid Days :</b> <span>{paidDay}</span></div>
                                                         </div>
                                                        <div className="col-md-5" style={{float:'right'}}>
                                                        <table
                                                            style={{ width: "100%", border: "1px solid #a3a3a3",textAlign:'center' }}
                                                        >
                                                            <tr
                                                            style={{
                                                                border: "1px solid #a3a3a3",
                                                                backgroundColor: "#51445f",
                                                                color: "white",
                                                            }}
                                                            >
                                                            <th
                                                                style={{
                                                                border: "1px solid #a3a3a3",
                                                                width: "10%",
                                                                }}
                                                            >
                                                                #
                                                            </th>
                                                            <th
                                                                style={{
                                                                border: "1px solid #a3a3a3",
                                                                width: "30%",
                                                                }}
                                                            >
                                                                Leave Type
                                                            </th>
                                                            <th
                                                                style={{
                                                                border: "1px solid #a3a3a3",
                                                                width: "30%",
                                                                }}
                                                            >
                                                                Taken Leave
                                                            </th>
                                                            <th
                                                                style={{
                                                                border: "1px solid #a3a3a3",
                                                                width: "30%",
                                                                }}
                                                            >
                                                                Total Available Till Now
                                                            </th>
                                                            </tr>
                                                            {leavestypelist.map((v, k) => {
                                                              var total_till_now =
                                                                Number(v.carryforward_cnt) +
                                                                Number(v.credit_cnt) -
                                                                Number(v.debit_cnt);
                                                            return (
                                                                <tr style={{ border: "1px solid #a3a3a3" }}>
                                                                <th style={{ border: "1px solid #a3a3a3" }}>
                                                                    {k + 1}
                                                                </th>
                                                                <th style={{ border: "1px solid #a3a3a3" }}>
                                                                    {v.name}
                                                                </th>
                                                                <th
                                                                    style={{
                                                                    border: "1px solid #a3a3a3",
                                                                    backgroundColor: "#fb6e52",
                                                                    }}
                                                                >
                                                                    {Number(v.lop_cnt)}
                                                                </th>
                                                                <th
                                                                    style={{
                                                                    border: "1px solid #a3a3a3",
                                                                    backgroundColor: "#a0d468",
                                                                    }}
                                                                >
                                                                    {total_till_now > 0 ? total_till_now : 0}
                                                                </th>
                                                                </tr>
                                                            );
                                                            })}
                                                            <tr style={{ border: "1px solid #a3a3a3" }}>
                                                            <th style={{ border: "1px solid #a3a3a3" }}></th>
                                                            <th style={{ border: "1px solid #a3a3a3" }}>Total</th>
                                                            <th style={{ border: "1px solid #a3a3a3" }}>{debited_leaves}</th>
                                                            </tr>
                                                        </table>
                                                        </div>
                                                    </div>


                   
                                                    <table className="mt-4 table table-bordered">
                                                        <thead className="bg-dark text-white">
                                                            <tr>
                                                                <th style={{padding: '7px'  }} scope="col">Earnings</th>
                                                                <th style={{padding: '7px'  }} scope="col">Amount</th>
                                                                <th style={{padding: '7px'  }} scope="col">Deductions</th>
                                                                <th style={{padding: '7px'  }} scope="col">Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td scope="row" colSpan="2" style={{ padding: '0px' }}>
                                                                    <table style={{ width: '100%' }}>
                                                                        {
                                                                            salaryMonth.map((val, key) => {
                                                                                if (val.typ == 'earning') {

                                                                                    return (
                                                                                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                                                                            <th style={{ border: 'none' }}> {val.earning_name}</th>
                                                                                            <td style={{ textAlign: 'center', border: 'none' }}>{val.amount}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }

                                                                            })
                                                                        }
                                                                    </table>
                                                                </td>
                                                                <td colSpan="2" style={{ padding: '0px' }}>
                                                                    <table style={{ width: '100%' }}>
                                                                        {
                                                                            salaryMonth.map((val, key) => {
                                                                                if (val.typ == 'deduction') {
                                                                                    return (
                                                                                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                                                                            <th style={{padding: '7px'  }}> {val.deduction_name}</th>
                                                                                            <td style={{ textAlign: 'center',padding: '7px'  }}>{val.amount}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </table>
                                                                </td>

                                                            </tr>

                                                            <tr className="border-top">
                                                                <th scope="row">Total Earning</th>
                                                                <th>{earning_tot}</th>
                                                                <th>Total Deductions</th>
                                                                <th>{deduction_tot}</th>
                                                            </tr>

                                                            

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div style={{paddingTop: '10px',fontSize: '23px'}}><b>Net Amount : </b> {earning_tot-deduction_tot}</div>

                                                <div className="d-flex justify-content-end">
                                                    <div className="d-flex flex-column mt-2"> <span className="fw-bolder"></span> <span className="mt-4">Authorised Signatory</span> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Salaryslip_pdf