import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../services/Apiconnect.js";
import "../ems_component/pages/ems_pages.css";
import "../ems_component/pages/userstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import './public.css';
import Loadder from "./webview_lodder";


function Leave_details(props) {
  const alert = useAlert();

//   const localuser = JSON.parse(localStorage.getItem('user'));
//   let emp = Apiconnect.decrypt(localuser.id);
  let emp = props.match.params.id;
  let leave_id = props.match.params.leave_id;
  let company_id = props.match.params.company_id;


  const [loader, setLoader] = useState(false);

  const [creadited_leaves, setcreadited_leaves] = useState(0);
  const [debited_leaves, setdebited_leaves] = useState(0);
  const [carryforward_leaves, setcarryforward_leaves] = useState(0);
  const [leavestypelist, setleavestypelist] = useState([]);
  const [leaveList, setleaveList] = useState([]);
  const [reportor, setReportorList] = useState({ first_name: '', last_name: '' });
  const { first_name, last_name } = reportor;



  useEffect(() => {
    getReportor();
    getLeaveByType();
    getLeaveList();
  }, []);

  const getLeaveList = () => {
    setLoader(true);
    Apiconnect.getData(`public/employeeleavedetails/${emp}/${leave_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleaveList(_xtract.reverse());
       setLoader(false);
    });
  }
  const getReportor = () => {
    Apiconnect.getData(`public/leaveapprovalauthority/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setReportorList({ first_name: _xtract[0].first_name, last_name: _xtract[0].last_name });
    });
  }

  //////////////----------------Leave summary------------------///////////////////////

  const getLeaveByType = () => {
    Apiconnect.getData(`public/getAllLeaveByType/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleavestypelist(_xtract.reverse());
    });

    Apiconnect.getData(`public/totalcreditedleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].creditedleaves !== null) {
        setcreadited_leaves(_xtract[0].creditedleaves);
      }
    });

    Apiconnect.getData(`public/totaltakenleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].debitleaves !== null) {
        setdebited_leaves(_xtract[0].debitleaves);
      }
    });

    Apiconnect.getData(`public/totalcarryforwardleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].carryforwardleaves !== null) {
        setcarryforward_leaves(_xtract[0].carryforwardleaves);
      }
    });

  }

  const leaveApprove = e => {

    let info = { id: e, status: 1, approved_by: emp };
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };
    Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
       alert.success(response.data.message);
       getLeaveList();
       getLeaveByType();
    });

 }

 const leaveCancle = e => {
    let info = { id: e, status: 2, approved_by: emp };
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };
    Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
       alert.success(response.data.message);
       getLeaveList();
       getLeaveByType();
    });
 }

 console.log('leaveList',leaveList)

  return (
    <>
  
      <div id="content-page" className="" style={{marginLeft:'unset !important',overflow: 'hidden',background: '#e2e2e2',padding: '20px 0px',minHeight: '100vh'}}>
        <div className="container-fluid leave_div">

        {
         loader ? <Loadder/>:
         <>
          <div className="row"  style={{fontSize:'13px'}}>
            
          <div className='="col-md-8 col-sm-8'>
            <div className="col-md-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Leave details of {leaveList.length > 0 ? leaveList[0].name : ''}</h4>
                  </div>
                </div>
                <div className="iq-card-body padLRTzero">

                  <div className="table-responsive">

                    <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                      <thead>
                        <tr className="clr">
                          <th>#</th>
                          <th>Applied On</th>
                          <th>Leave Type</th>
                          <th>Reason</th>
                          <th>Days</th>
                          <th>Half Day Type</th>
                          <th>Date</th>
                          <th>Opening</th>
                          <th>Available</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>

                        {leaveList.map((val, key) => {

                          var action1 = '';
                          if (val.status === 0) {
                             var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                             var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                             var action1 = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Cancel</div>;
                          }
                          if (val.status === 1) {
                             var status = <div className=" badge badge-pill badge-success">Approved</div>;
                             var action = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Cancel</div>;
                          }
                          if (val.status === 2) {
                             var status = <div className=" badge badge-pill badge-danger">Cancelled</div>;
                             var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                          }

                          return (
                            <tr key={key}>
                              <th scope="row">{key + 1}</th>
                              <td>{val.applied_on}</td>
                              <td>{val.name}</td>
                              <td>{val.reason}</td>
                              <td>{val.leave_count}</td>
                              <td style={{textAlign:'center'}}>{val.day_typ ? val.day_typ:'-'}</td>
                              <td>{val.leave_start_date1} to {val.leave_end_date1}</td>
                              <td></td>
                              <td></td>
                              <td>
                                {status}
                              </td>
                              <td>{action} {action1}</td>
                            </tr>
                          );
                        })}

                      </tbody>
                    </table>
                  </div>




                </div>
              </div>
            </div>
          </div>
            <div className="col-md-4 col-sm-4">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Leave summary</h4>
                  </div>
                </div>
                <div className="iq-card-body">




                  <div className="row">
                    <div className="col-md-6">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themeprimary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themeprimary">{creadited_leaves + carryforward_leaves}</span>
                          <div className="databox-text darkgray">
                            Total Leave Credited</div>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themesecondary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themesecondary">{debited_leaves}</span>
                          <div className="databox-text darkgray">
                            Total Leave Taken</div>

                        </div>
                      </div>
                    </div>

                    <div className="col-md-12" style={{textAlign: 'center',marginBottom:'20px'}}>
                      <table style={{width:'100%',border: '1px solid #a3a3a3'}}>
                        <tr style={{border: '1px solid #a3a3a3',backgroundColor: '#51445f',color: 'white'}}>
                          <th style={{border: '1px solid #a3a3a3',width:'10%'}}>#</th>
                          <th style={{border: '1px solid #a3a3a3',width:'30%'}}>Leave Type</th>
                          <th style={{border: '1px solid #a3a3a3',width:'30%'}}>LOP</th>
                          <th style={{border: '1px solid #a3a3a3',width:'30%'}}>Total Available Till Now</th>
                        </tr>
                        {
                          leavestypelist.map((v,k)=>{
                            var total_till_now = Number(v.carryforward_cnt) + Number(v.credit_cnt) - Number(v.debit_cnt);
                            return(
                              <tr style={{border: '1px solid #a3a3a3'}}>
                                <th style={{border: '1px solid #a3a3a3'}}>{k+1}</th>
                                <th style={{border: '1px solid #a3a3a3'}}>{v.name}</th>
                                <th style={{border: '1px solid #a3a3a3',backgroundColor: '#fb6e52'}}>{Number(v.lop_cnt)}</th>
                                <th style={{border: '1px solid #a3a3a3',backgroundColor: '#a0d468'}}>{total_till_now > 0 ?total_till_now:0}</th>
                              </tr>
                            );
                          })
                          
                        }
                      </table>
                    </div>
                    <div className="col-md-12">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themefourthcolor">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themefourthcolor">
                            {carryforward_leaves}</span>
                          <div className="databox-text darkgray">
                            Carry forward from last year</div>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themeprimary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-user"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themeprimary">
                            {first_name} {last_name}
                          </span>
                          <div className="databox-text darkgray">
                            Approval Authority</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           



          </div>




         
          </>
          }
        </div>
      </div>

  
    </>
  )
}

export default Leave_details
