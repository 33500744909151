import React, { useState, useEffect, useRef } from "react";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loadder from "./Loadder";
import Axios from "axios";
import { CSVLink } from "react-csv";
import Modal from "react-modal";

function Monthly_attendance_report() {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp = Apiconnect.decrypt(localuser.id);

  const csvLink = useRef();
  // const [from_date,setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [from_date, setFromDate] = useState(new Date());
  const [emp_id, setempid] = useState(null);
  const [modalLodder, setModalLodder] = useState(false);
  const [dwnEmpAttendanceList, setDwnEmpAttendanceList] = useState([]);

  const onInputChange = (e) => {
    setempid(e.target.value);
  };

  useEffect(() => {
    empdata();
  }, []);
  const [empList, setEmpList] = useState([]);
  const [dailyAttendanceList, setDailyAttendanceList] = useState([]);

  const empdata = () => {
    Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setEmpList(_xtract.reverse());
      }
    );
  };

  const submit = () => {
    if (from_date != null) {
      var from = format(from_date, "yyyy-MM");
    }

    let info = {
      company_id: Apiconnect.get_company_id(),
      selected_date: from,
      emp_id: emp_id,
    };
    // console.log(info);
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };

    setModalLodder("true");
     Apiconnect.postData(`employee/monthlyAttendaceinfo`,inf_a).then((response) => {
    // Axios.post(
    //   `http://localhost:3050/api/employee/monthlyAttendaceinfo`,
    //   inf_a
    // ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
    //   console.log(_xtract);
      setDwnEmpAttendanceList(_xtract.reverse());
      setModalLodder(false);

      csvLink.current.link.click();
    });
  };

  const customStyles1 = {
    content: {
      top: "55%",
      width: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "unset ",
      backgroundColor: "unset ",
    },
  };

  console.log(dailyAttendanceList);

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            {/* ////////////------------>Lodder<------------////////////////// */}
            <div>
              <Modal isOpen={modalLodder} style={customStyles1}>
                {<Loadder />}
              </Modal>
            </div>
            {/* ////////////------------>Lodder<------------////////////////// */}

            <div className="col-sm-12">
              <div className="iq-card">
                <div>
                  <h4 className="card-title hd">Monthly Atteandance</h4>
                </div>
                <div className="iq-card-body">
                  <div className="row p_t_15">
                    <div className="col-md-3">
                      Date : <br />
                      <DatePicker
                        selected={from_date}
                        onChange={(date) => setFromDate(date)}
                        maxDate={new Date()}
                        dateFormat="MM/yyyy"
                        className="form-control"
                        showMonthYearPicker
                      />
                    </div>

                    <div className="col-md-3">
                      User : <br />
                      <select
                        className="form-control"
                        name="emp_id"
                        value={emp_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option value="">Select User</option>
                        {empList.map((val, key) => {
                          return (
                            <option key={key} value={val.id}>
                              {val.first_name} {val.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="button"
                        className="btn btn-success"
                        value="Submit And Download"
                        style={{ marginTop: "23px" }}
                        onClick={(e) => submit(e)}
                      />
                      <CSVLink
                        data={dwnEmpAttendanceList}
                        filename="employee-attendance.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      />
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "25px" }}>
                    <div className="col-lg-12">
                      <div className="iq-card"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Monthly_attendance_report;
