import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Apiconnect from "../../services/Apiconnect.js";
import '../pages/admin.css';
// import Chart from "react-google-charts";
// import Calendar from "./Calendar.js";

function Dashboard_superadmin() {

   // getDeparmentCount(); getEmployeeCount();

   useEffect(() => {  getCompanyCount();getLeaveList();}, []);
   const [emp_count, setEmpCount] = useState([]);
   const [allcompany, setCompanyCount] = useState([]);

   const getCompanyCount = () => {
      Apiconnect.getData(`company/getallcompanydata`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         //  console.log(_xtract);
         setCompanyCount(_xtract.reverse());
      });
   }

   // const getEmployeeCount = () => {
   //    Apiconnect.getData(`employee/getemployeecount/${Apiconnect.get_company_id()}`).then((response) => {
   //       let _xtract = Apiconnect.decrypt_obj(response.data.data);
   //       setEmpCount(_xtract.total);
   //    });
   // }

   // const [count_cat, setCatCount] = useState([]);
   // const getDeparmentCount = () => {
   //    Apiconnect.getData(`company_category/getcount/${Apiconnect.get_company_id()}`).then((response) => {
   //       let _xtract = Apiconnect.decrypt_obj(response.data.data);
   //       setCatCount(_xtract.total);
   //    });
   // }

    const [leaveList, setleaveList] = useState([]);

   const getLeaveList = () => {
      Apiconnect.getData(`employee_leave/getallbyapprove/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setleaveList(_xtract.reverse());
      });
   }

   const changeCompany = (id) => {
      const local_user = JSON.parse(localStorage.getItem('user'));
      var company_id1 = Apiconnect.encrypt(id+'');
      local_user.company_id = company_id1;
      var comp_name = allcompany.find(Element => Element.id == id);
      if(id == Apiconnect.get_company_id()){
         local_user.company_id = '';
         local_user.company_name = '';
      } else {
         local_user.company_name = comp_name.company_name;
      }
      localStorage.removeItem("user");    
      localStorage.setItem("user", JSON.stringify(local_user));
      window.location.reload();
      // console.log(local_user);
   }


 
   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">



               <div className="row row-eq-height">
                  {/* <div className="col-lg-2 col-md-12"> */}
                  <div className="col-lg-12 col-md-12" style={{display:'inline-flex'}}>
                     {
                        allcompany.map((val,key)=>{

                           var br = '';
                           if(val.id == Apiconnect.get_company_id()){
                              var br = 'compbr';
                           }
                           return(
                              <div key={key} className="col-lg-3 col-md-3 " style={{paddingLeft:'0px',cursor: 'pointer'}} onClick={e=>changeCompany(val.id)}>
                              <div className="row">
                              <div className="col-md-12">
                                 <div className={br +" iq-card compbtn"}>
                                    <div className="iq-card-body">
                                       <div className="row">
                                          <div className="col-lg-12 mb-2 d-flex justify-content-between">
                                              <span className="h5 text-dark mb-0 d-inline-block w-100">{val.company_name}</span>
                                          
                                          </div>
                                          <div className="col-lg-12 mt-3">
                                            <table>
                                               <tr><td>Admin </td><td style={{width:'30px',textAlign:'center'}}> : </td><td> {val.admin_cnt}</td></tr>
                                               <tr><td>Employee</td><td style={{width:'30px',textAlign:'center'}}> : </td><td> {val.emp_cnt}</td></tr>
                                               <tr><td>Department </td><td style={{width:'30px',textAlign:'center'}}> : </td><td> {val.dept_cnt}</td></tr>
                                            </table>
                                          </div>
                                       </div>
                                       <div className="row" style={{marginTop:'10px'}}>  
                                       <div className="col-md-3">
                                       </div>
                                       <div className="col-md-8">
                                       <button className="btn btn-primary btn-sm">Reload Data</button>
                                       </div>
                                       <div className="col-md-2">
                                       </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>   
                           </div>
                           )
                        })
                     }

                  </div>

                     {/* <div className="row">
                        <div className="col-md-12">
                           <div className="iq-card">
                              <div className="iq-card-body">
                                 <div className="row">
                                    <div className="col-lg-12 mb-2 d-flex justify-content-between">
                                       <div className="icon iq-icon-box rounded-circle iq-bg-success rounded-circle" data-wow-delay="0.2s">
                                          <i className="ri-clockwise-2-line"></i>
                                       </div>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                       <h6 className="card-title text-uppercase text-secondary mb-0">Department</h6>
                                       <span className="h2 text-dark mb-0 d-inline-block w-100">{count_cat}</span>
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div> */}
                     
                  {/* </div> */}

                  {/* <div className="col-lg-10 col-md-12">
                     <div className="iq-card" data-wow-delay="0.8s">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Daily User Attendance</h4>
                           </div>

                        </div>
                        <div className="iq-card-body p-0">


                           <div style={{ display: 'flex', maxWidth: 900,marginLeft: '30px',marginTop: '10px' }}>
                              <Chart
                                 width={'100%'}
                                 height={'300px'}
                                 chartType="Bar"
                                 loader={<div>Loading Chart</div>}
                                 data={dataattr}
                                 options={{
                                 
                                    hAxis: {
                                       title: 'Total Population',
                                       maxValue: 100,
                                     },
                                    
                                 }}
                               
                                 rootProps={{ 'data-testid': '1' }}
                              />

                           </div>

                        </div>
                     </div>
                  </div> */}

                  {/* <div className="col-lg-12" style={{'margin-bottom': '20px',}}>
                 
                 <div className="col-lg-6">
                 {<Calendar />}
                 </div>

                 

              </div> */}

                  <div class="col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Leaves</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="table-responsive">
                              <table class="table mb-0 table-borderless">
                                 <thead>
                                    <tr>
                                       <th>#</th>
                                       <th>Name</th>
                                       <th>Company Name</th>
                                       <th>Department</th>
                                       <th>Applied On</th>
                                       <th>Leave Type</th>
                                       <th>Reason</th>
                                       <th>Days</th>
                                       <th>Date</th>
                                       <th>Approved By</th>
                                       <th>Status</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {leaveList.map((val, key) => {

                                       if (val.status == 0) {
                                          var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                       }
                                       if (val.status == 1) {
                                          var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                       }
                                       if (val.status == 2) {
                                          var status = <div className=" badge badge-pill badge-danger">Cancelled</div>;
                                       }

                                       return (
                                          <tr key={key}>
                                             <th scope="row">{key + 1}</th>
                                             <td>{val.emp_name}</td>
                                             <td>{val.company_name}</td>
                                             <td>{val.department}</td>
                                             <td>{val.applied_on}</td>
                                             <td>{val.name}</td>
                                             <td>{val.reason}</td>
                                             <td>{val.leave_count}</td>
                                             <td>{val.leave_start_date1} to {val.leave_end_date1}</td>
                                             <td>{val.first_name} {val.last_name}</td>
                                             <td>
                                                {status}
                                             </td>
                                          </tr>
                                       );
                                    })}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>


                  {/* <div class="col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Upcomming Birthday</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="table-responsive">
                              <table class="table mb-0 table-borderless">
                                 <thead>
                                    <tr>
                                       <th>#</th>
                                       <th>Name</th>
                                       <th>Date</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {holidayList.map((val, key) => {

                                       return (
                                          <tr key={key}>
                                             <th scope="row">{key + 1}</th>
                                             <td>{val.first_name} {val.last_name}</td>
                                             <td>{val.date_of_birth2}</td>
                                          </tr>
                                       );
                                    })}
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div> */}
               </div>
































            </div>
         </div>


      </>
   )
}

export default Dashboard_superadmin
