import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import "react-datepicker/dist/react-datepicker.css";
import FullScreenLoading from 'react-fullscreen-loading';
import { confirmAlert } from "react-confirm-alert";
import { CSVLink } from "react-csv";
import jsPDF from 'jspdf';
var converter = require('number-to-words');

function Salary_disbursement() {
    const alert = useAlert();
    const locusr = JSON.parse(localStorage.getItem("user"));
    let company_name = locusr.company_name;

   const [salaryMonths, setSallaryMonths] = useState([]);
   const [salaryData, setSallaryData] = useState([]);
   const [fullScreenLoader, setFullScreenLoader] = useState(false);
   const [allchecked, setallchecked] = useState(null);
   const [selectedEmp, setSelectedEmp] = useState([]);
   const [selectedMonth, setSelectedMonth] = useState(null);
   const [disbursementStatus, setDisbursementStatus] = useState([]);
   const [companyaddress, setCompanyData] = useState([])
   const [salaryDataCvs, setSallaryDataCvs] = useState([]);

   useEffect(() => {getMonthData();getDisbursementData();getCompany()}, []);

   const getCompany = () => {         
    Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
          let ern = Apiconnect.decrypt_obj(response.data.data);
          setCompanyData(ern[0].address_one)
         
      });
  };

   const getMonthData = () => {
    Apiconnect.getData(`employee_monthly_salary/getSalarySlipAllMonths/${Apiconnect.get_company_id()}`).then((response) => {
       let _xtract = Apiconnect.decrypt_obj(response.data.data);
       var monthsList=_xtract;
       setSallaryMonths(monthsList);
    });
 };

 const getDisbursementData = () => {
  Apiconnect.getData(`employee_salary/getSalaryDisbursmentStatus`).then((response) => {
     let _xtract = Apiconnect.decrypt_obj(response.data.data);
     setDisbursementStatus(_xtract);
  });
};


 const months = {'01' :'JAN','02' :'FEB','03' :'MAR','04' :'APR','05' :'MAY',
 '06' :'JUN','07' :'JUL','08' :'AUG','09' :'SEP','10' :'OCT','11' :'NOV','12' :'DEC'};


 const onChangeMonth = (e) => {

    var year = e.target.value;
    if(year != ''){
        setSelectedMonth(year)
        getSalaryData(year)
        getSalaryDataCvs(year)
    }else{
        setSelectedMonth('')
        setSallaryData([]);
        setSallaryDataCvs([]);
    }
   
   
 }

 const getSalaryData = (yyyymm,type=null) => {
    setFullScreenLoader(true)
    Apiconnect.getData(`employee_salary/getSalaryEmployeeMonthsWise/${Apiconnect.get_company_id()}/${yyyymm}/${type}`).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        var monthsList=_xtract;
        setSallaryData(monthsList);
        setFullScreenLoader(false)

        console.log('monthsListPdf',monthsList)
     });
 }

 const getSalaryDataCvs = (yyyymm,type=null) => {
  Apiconnect.getData(`employee_salary/getSalaryEmployeeMonthsWiseCvs/${Apiconnect.get_company_id()}/${yyyymm}/${type}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      var monthsList=_xtract;
      setSallaryDataCvs(monthsList);
      console.log('monthsListCvs',monthsList)
   });
}


 const sellectAll = (e) => {
    if (e.target.checked === true) {
      // console.log(e.target.checked);
      setallchecked("checked");
      let ob = {};
      salaryData.map((val, key) => {
        ob[val.id] = val.id;
      });
      setSelectedEmp(ob);
      // setSelectedCountEmp(ob);
    } else {
      setallchecked(null);
      setSelectedEmp([]);
      // setSelectedCountEmp([]);
    }
  };

  const onInputChange2 = (e) => {
    setallchecked(null);

    if (e.target.checked === true) {
      setSelectedEmp({
        ...selectedEmp,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });
    }
  };


  const isEmployeeDisbursement = (status) => {
    if (selectedEmp != "") {
      disbursement(status)
    } else {
      confirmAlert({
        title: "Select Employee",
        buttons: [
          {
            label: "OK",
          },
        ],
      });
    }
  };

  const disbursement = (status) => {
    const inf_a = {yyyymm:selectedMonth, company_id : Apiconnect.get_company_id(), status: status, employee: selectedEmp };
    Apiconnect.postData("employee_salary/salary_disbursment", inf_a).then(
     (response) => {
       getSalaryData(selectedMonth);
       setSelectedEmp([]);
       alert.success("Data Update Successfully");
     }
   );
  }

  const seleteFilter = (e)=> {
    var value = e.target.value;

    if(value != ''){
        getSalaryData(selectedMonth,value)
    }else{
        getSalaryData(selectedMonth)
    }
    
  }


  const downloadBankLetterPdf = () => {

    var year= selectedMonth.slice(0, 4);
    var month= selectedMonth.slice(4, 6);

    let col = ['Sr.no']
    var head = Object.keys(salaryData[0])
    const valuesToRemove = ['id', 'EmployeeID','status']
    const filteredItems = head.filter(item => !valuesToRemove.includes(item))

    let colNew = ['Sr.no','Name','SB A/C No','Amount']

    var doc = new jsPDF('p', 'pt','a3');
    col.push(...filteredItems);
    var rows = [];

    var cnt = 1;
    salaryData.map((key, key1) => {    
      var sr = cnt++;  
      var arr = [sr];
      filteredItems.map(val => (
          arr.push(key[val])
       ))  
       rows.push(arr);
    }) 

    let total = 0;
    salaryData.forEach(element => {      
      total = total+element.DisburseAmount;
    }); 
    var arr1 = ['','','Grand Total',total];
    rows.push(arr1);

    doc.setFontSize(12);
    doc.text(280,25,company_name.toUpperCase());
    doc.setFontSize(10);
    doc.text(350,40,companyaddress);
  
    doc.setFontSize(10);
    doc.text(20,65,'To');
    doc.setFontSize(10);
    doc.text(20,75,'The Manager,');

    doc.setFontSize(12);
    doc.setFont('DINBold');
    doc.text(240,100,'SUB : SALARY TRANSFER FOR THE MONTH OF GZB('+year+')/' +  months[month]);

    doc.setFontSize(12);
    doc.text(20,130,'Dear Sir');
    doc.setFontSize(11);
    doc.text(22,145,'We send here with statements showing a total amount of RS '+total+' payable as Salary for the month of GZB(2022-23)/July');
    doc.setFontSize(11);
    doc.text(22,160,'Please transfer individual amount accounts as per the statement');

    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page

    doc.autoTable(colNew, rows, { 
      theme: 'grid',
      // showHead:'firstPage',
      startY: pageCurrent == 1 ? 200 : 50 ,
      margin: {left:5,right:5},
      headStyles:{fillColor:'#dee2e6',valign:'middle'},
      styles: { fontSize: 8,cellWidth:'auto',textColor:20,valign:'middle',halign:'center' },
      });

      let finalY = doc.lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.text(20,finalY+20,'Total Amount in Words : - '+converter.toWords(total));

      doc.setFontSize(12);
      doc.text(40,finalY+100,'Principal');
      doc.setFontSize(12);
      doc.text(700,finalY+100,'Manager');

      doc.save('BankLetterPdf.pdf');
  }


   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">

            {fullScreenLoader
            ? (
              <FullScreenLoading loading loaderColor="#3498db" />
            )
             
            : null
            }

               <div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card">
                        <div>
                           <h4 className="card-title hd">Salary disbursement</h4>
                          
                        </div>
                            
                        <div className="iq-card-body">

                      

                           <div className="row p_t_15">
                       
                              <div className="col-md-3">
                                Months : <br />
                                 <select className="form-control" name="month" onChange={(e)=>onChangeMonth(e)}>
                                    <option value="">Select Month</option>
                                    {
                                    salaryMonths.map((value,key)=>{
                                      if(value.status == 1){
                                        return (
                                          <option value={value.year+''+value.month}>{months[value.month]+'-'+value.year}</option>
                                      )
                                      }
                                       
                                    })
                                    }
                                   
                                 </select>
                              </div>

                              {
                                selectedMonth ?
                                (
                                    <>

                                      {
                                        disbursementStatus.map((val, key) => {
                                          return (
                                            <div className="col-md-1" style={{textAlign:'center',marginRight:'20px'}} >
                                              <button
                                              style={{ marginTop: "20px" }}
                                                  onClick={() => {
                                                      isEmployeeDisbursement(val.status);
                                                  }}
                                                  className="btn btn-info mr-2"
                                              >
                                                 {val.name}
                                              </button>
                                          </div>
                                          )
                                        })
                                      }

                                 
                                    <div className="col-md-2">
                                 
                                      
                                    </div>        
                                    <div className="col-md-2">
                                        Filter : <br />
                                        <select className="form-control" name="month" onChange={(e)=>seleteFilter(e)}>
                                        <option value="">All</option>
                                          {
                                            disbursementStatus.map((val, key) => {
                                              return (
                                                <option value={val.status}>{val.name}</option>
                                              )
                                            })
                                          }
                                        </select>
                                    </div>

                                    <div style={{marginTop:'5px'}}>
                                     <CSVLink
                                        filename={"employee-salary_disbursement.csv"}
                                        style={{ marginTop: "20px", float: "right" }}
                                        className="btn btn-warning btn-sm"
                                        data={salaryDataCvs}
                                        >
                                        <i className="fa fa-download"></i>Employee Data
                                    </CSVLink>
                                    </div>
                                    <div style={{marginTop:'5px'}}>
                                    <button  className="btn btn-primary btn-sm" onClick={() => downloadBankLetterPdf()}
                                     style={{ marginTop: "20px",marginLeft:'10px', float: "right" }} >
                                             <i className="fa fa-download"></i>
                                             {` `} Bank Letter Pdf
                                          </button>

                                    </div>
                                    </>
                                )
                                : null
                              }

                           </div>

                           <div className="row">

                              <div className="table-responsive">

                                 <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                    <thead>
                                       <tr className="clr" >
                                       <th scope="col">
                                            <input
                                                type="checkbox"
                                                name="select_all"
                                                checked={allchecked}
                                                onChange={(e) => sellectAll(e)}
                                            />
                                          </th>
                                          <th>#</th>
                                          <th>Employee ID</th>
                                          <th>Sraff Name</th>
                                          <th>Bank Account No</th>
                                          <th>Disburse Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                    {salaryData.map((val, key) => {

                                        var ck = Object.keys(selectedEmp).find(function (
                                            element
                                        ) {
                                            return selectedEmp[element] == val.id;
                                        });

                                        var ck1 = "";
                                        if (ck !== undefined) {
                                            ck1 = true;
                                        }

                                          return (
                                            <tr key={key}
                                             style={{color:val.status == 1 ? 'green' : val.status == 2 ? 'red' : ''}}>
                                                 <th scope="row">
                                                    <input
                                                    type="checkbox"
                                                    name={val.id}
                                                    value={val.id}
                                                    checked={ck1}
                                                    onChange={(e) => onInputChange2(e)}
                                                    
                                                    />
                                                </th>
                                               <th scope="row">{key + 1}</th>
                                               <td>{val.EmployeeID}</td>
                                               <td>{val.StaffName}</td>
                                               <td>{val.BankAccountNo}</td>
                                               <td>{val.DisburseAmount}</td>
                                            </tr>
                                         );
                                    })}
                                    </tbody>
                                    
                                 </table>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>


      </>
   )
}

export default Salary_disbursement
