import React, { Component } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { withAlert } from 'react-alert';
import { useAlert } from "react-alert";
import '../pages/admin.css';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import FullScreenLoading from 'react-fullscreen-loading';



class Employee_salary_template_edit extends Component {

   
    constructor(props) {
        super(props);
        this.state = { earning_head: [], emp_info: '', deduct_head: [], reimbursement_head: [],formula: [],template_name:'',template_id:'',
        allcheckedEr:'',allcheckedDr:'',selectedHeads:[],selectedHeadsEr:[],selectedHeadsDr:[],fullScreenLoader:false };

        this.get_earning_head();
        this.getdeductList();
        this.getformulaList();
        this.getTemplateList();
    }

     getTemplateList = ()=> {
        var temp_id = this.props.match.params.id;
        Apiconnect.getData(`employee_salary_template/getTemplateById/${temp_id}`).then((response)=>{
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           this.setState({ template_name: _xtract[0].name });
           this.setState({ template_id: _xtract[0].id });

           const erArr = _xtract[0].display_salary_er !== '' ? _xtract[0].display_salary_er.split(',') : [];
           const drArr = _xtract[0].display_salary_dr !== '' ? _xtract[0].display_salary_dr.split(',') : [];
           let ob1 = {};
           for (let er of erArr){
             ob1[er] = parseInt(er);
           }
           this.setState({selectedHeadsEr : ob1})
         
           let ob = {};
           for (let dr of drArr){
             ob[dr] = parseInt(dr);
           }
           this.setState({selectedHeadsDr : ob})
           
        });
      }

    getformulaList = () => {
        Apiconnect.getData(`formula/getall`).then((response) => {
            let ern = Apiconnect.decrypt_obj(response.data.data);
            this.setState({ formula: ern });
        });
    };

    get_earning_head = () => {
        var temp_id = this.props.match.params.id;
        Apiconnect.getData(`employee_salary_template/getAllheadById/${temp_id}`).then((response) => {
            let ern = Apiconnect.decrypt_obj(response.data.data);
            // console.log('ern=>>',ern);
            this.setState({ earning_head: ern });
        });
    };
    getdeductList = () => {
        var temp_id = this.props.match.params.id;
        Apiconnect.getData(`employee_salary_template/getDeductheadById/${temp_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            this.setState({ deduct_head: _xtract });

        });
    };

    changeFormula = (e,i) => {
        let ki = i.key;
        let va = e.target.value; 
        // console.log(ki);
        // console.log(va);
        if (va > 0) {
            this.formula_man(ki, va);
        } else {
            let markers = this.state.deduct_head;
            ////markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ deduct_head: markers });
        }
    };

    formula_man = (ki, va) => {
        Apiconnect.getData(`employee_salary/get_formula/${va}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            var logic = _xtract[0].logic;

            var itm = {};
            this.state.earning_head.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });
            this.state.deduct_head.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });
            const kkk = Object.keys(itm);
            const vvv = Object.values(itm);
            var out = 0;
            logic.map((v, k) => {
                var if_con = v.if_condition;
                var chk = v.check_value;
                var opr = v.f_operator;

                kkk.map((v1, k1) => {
                    var finder = v1;
                    var rplcer = vvv[k1];



                    var rr = if_con.replace(finder, rplcer);
                    if_con = rr.toString();
                });

                var ifcon_o = eval(if_con);
                if (opr == 'BETWEEN') {
                    var rng = chk.split("-");
                    var ifcon_out = ifcon_o + '>=' + rng[0] + ' && ' + ifcon_o + '<=' + rng[1];
                } else {
                    var ifcon_out = ifcon_o + opr + chk;
                }
                //console.log(ifcon_out);
                //console.log(eval(ifcon_out));

                if (eval(ifcon_out)) {
                    out = v.f_then;
                    //console.log('--====-->>');
                    //console.log(out);
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        //console.log(finder);
                        //console.log(rplcer);
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();


                    });
                    //console.log(out);


                    out = eval(out);
                    //console.log(out);
                   // console.log('--====-->>');
                }else{
                    out = v.f_else;                    
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();
                    });
                    //console.log(out);
                    out = eval(out);
                    //console.log(out);
                    //console.log('--====-->>');
                }
            });
            console.log('::: PTAX -->::' + out);
            let markers = this.state.deduct_head;
            markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ deduct_head: markers });

        });
    }

    changeFormula_er = (e,i) => {
        let ki = i.key;
        let va = e.target.value; 
        //console.log(ki);
        //console.log(va);
        if (va > 0) {
            this.formula_man_er(ki, va);
        } else {
            let markers = this.state.earning_head;
            //markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ earning_head: markers });
        }
    };

    formula_man_er = (ki,va) =>{
        Apiconnect.getData(`employee_salary/get_formula/${va}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);            
            var logic = _xtract[0].logic;
  
            var itm = {};
            this.state.earning_head.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });          
            const kkk = Object.keys(itm);
            const vvv = Object.values(itm); 
            var out =0;

            logic.map((v, k) => {
              var if_con = v.if_condition;
              var chk = v.check_value;
              var opr = v.f_operator;
  
              kkk.map((v1, k1) => {
                var finder = v1;
                var rplcer = vvv[k1];
  
                
  
                var rr = if_con.replace(finder, rplcer);
                if_con = rr.toString();
              });
  
                var ifcon_o = eval(if_con);
                if(opr=='BETWEEN'){ 
                    var rng = chk.split("-");                   
                    var ifcon_out = ifcon_o +'>='+ rng[0]+ ' && ' + ifcon_o +'<='+ rng[1];
                }else{
                    var ifcon_out =   ifcon_o+opr+chk;
                }
  
                if(eval(ifcon_out)){
                    out = v.f_then;
                    console.log('--====-->>');
                    console.log(out);
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1]; 
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();
                    });
                    console.log(out);
                    

                    out = eval(out);
                    console.log(out);
                    console.log('--====-->>');
                }
            }); 

            console.log('::: ERN-- -->::'+out);          
            let markers = this.state.earning_head;        
            markers[ki] = {...markers[ki], amount: out};
            markers[ki] = {...markers[ki], formula_id: va};          
            this.setState({earning_head:  markers});
            
        });
      }

      onInputChange = (e,i) =>{
        let ki = i.key;
        let markers = this.state.earning_head;
        markers[ki] = {...markers[ki], amount: e.currentTarget.value}; 
        this.setState({earning_head:  markers});
  
          let ddx = this.state.deduct_head; 
          ddx.map((vk, kk) => {
              if(vk.formula_id>0){
                this.formula_man(kk,vk.formula_id);
              } 
          })
  
  
          let ernz = this.state.earning_head; 
          ernz.map((vkx, kkx) => {
              if(vkx.formula_id>0){
                this.formula_man_er(kkx,vkx.formula_id);
              }
          })
  
          
      };

      onInputChange_deduct = (e,i) =>{
        let ki = i.keyx;
        let markers = this.state.deduct_head;        
        markers[ki] = {...markers[ki], amount: e.currentTarget.value};
        this.setState({deduct_head:  markers});
    };

    ontemplateInputChange = e =>{
        this.setState({template_name:  e.target.value});
    }
    
    
    submitForm = async e => {   
        this.setState({fullScreenLoader:true})
        //const alert = useAlert();       
        e.preventDefault(); 
        var empid = this.props.match.params.id;

        let info_enc =  Apiconnect.encrypt_obj(this.state.earning_head); 
        let deduct_head_enc =  Apiconnect.encrypt_obj(this.state.deduct_head); 
        let remb_head_enc =  Apiconnect.encrypt_obj(this.state.reimbursement_head);  
        let selectedE = Object.values(this.state.selectedHeadsEr).filter(Boolean).join(',');
        let selectedD = Object.values(this.state.selectedHeadsDr).filter(Boolean).join(',');


        const inf_a = { display_salary_er: selectedE,display_salary_dr:selectedD,enc: info_enc, enc_deduct: deduct_head_enc, enc_remb: remb_head_enc,company_id: Apiconnect.get_company_id(),template_name:this.state.template_name,template_id:this.state.template_id };
      // console.log(inf_a);
       //return false;
        await Apiconnect.postData("employee_salary_template/update",inf_a).then((response) => {
         // console.log(response);
          this.props.alert.success(response.data.message)
          this.setState({fullScreenLoader:false})
          this.props.history.push("/salary_template_list"); 
         
        }); 
  };

  sellectAll2 = (e) => {
    this.setState({allcheckedEr : null})
    if (e.target.checked === true) {
      this.setState({allcheckedEr : 'checked'})
      let ob = {};
      this.state.earning_head.map((val, key) => {
        ob[val.id] = val.id;
      });
      this.setState({selectedHeadsEr : ob})
    } else {
        this.setState({allcheckedEr : null})
        this.setState({selectedHeadsEr : []})
    }
  };

   onInputChangeEr = (e) => {
    this.setState({allcheckedEr : null})
    console.log(e.currentTarget.value);
    if (e.target.checked === true) {
        this.setState({ selectedHeadsEr: {
            ...this.state.selectedHeadsEr,
            [e.currentTarget.name]: e.currentTarget.value,
          }});
    } else {
      this.setState({ selectedHeadsEr: {...this.state.selectedHeadsEr, [e.currentTarget.name]: 0 }});
    }
  };

  sellectAll3 = (e) => {
    this.setState({allcheckedDr : null})
    if (e.target.checked === true) {
      this.setState({allcheckedDr : 'checked'})
      let ob = {};
      this.state.deduct_head.map((val, key) => {
        ob[val.id] = val.id;
      });
      this.setState({selectedHeadsDr : ob})
    } else {
        this.setState({allcheckedDr : null})
        this.setState({selectedHeadsDr : []})
    }
  };

  onInputChangeDr = (e) => {
    this.setState({allcheckedDr : null})
    if (e.target.checked === true) {
        this.setState({ selectedHeadsDr: {
            ...this.state.selectedHeadsDr,
            [e.currentTarget.name]: e.currentTarget.value,
          }});
    } else {
      this.setState({ selectedHeadsDr: {...this.state.selectedHeadsDr, [e.currentTarget.name]: 0 }});
    }
  };

    render() {

        return (

            <div id="content-page" className="content-page">
                <div className="container-fluid">
                {this.state.fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    : null
                    }

                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Employee Salary Template</h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <form onSubmit={e => this.submitForm(e)}>

                                <div className="row">
                                <div className="col-md-3">
                                 <div className="form-group">
                                    <label>Template Name <span className="required">*</span></label>
                                    <input type="text" className="form-control" name="template_name" 
                                     placeholder="Template Name" 
                                     value={this.state.template_name}
                                    onChange={e => this.ontemplateInputChange(e)} required  />
                                 </div>
                              </div>
                                </div>

                                <div className="border">
                                    <div className="row">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th width="160">Display heads</th>
                                                    <th width="120" scope="col">#</th>
                                                    <th scope="col">Head</th>
                                                    <th scope="col">Type</th>
                                                    {/* <th width="50%" scope="col">Set Amount</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <td style={{ background: "#948a9e", padding: "0.3rem", paddingLeft: '12px'}}>
                                                        <input
                                                    type="checkbox"
                                                    name="select_all"
                                                    checked={this.state.allcheckedEr}
                                                    onChange={(e) => this.sellectAll2(e)}
                                                    />
                                                    </td>
                                                    <td style={{ background: "#948a9e", padding: "0.3rem" }} colSpan="4"><h6 style={{ color: "#fff", 'font-weight': "bold" }}>Earning Head</h6></td>
                                                </tr>

                                                {this.state.earning_head.map((val, key) => {
                                                       let frmla = val.formula;
                                                       var ck = Object.values(this.state.selectedHeadsEr).find(element => element == val.id)
                                                       var ck1 = false;
                                                       if (ck !== undefined) {
                                                         ck1 = true;
                                                       }
                                                    return (
                                                        <tr key={key}>
                                                             <td>
                                                            <input
                                                                type="checkbox"
                                                                name={val.id}
                                                                value={val.id}
                                                                checked={ck1}
                                                                onChange={(e) => this.onInputChangeEr(e)}
                                                                />
                                                            </td>
                                                            <td>{key + 1}</td>
                                                            <td>{val.name}  </td>
                                                            <td>
                                                                <select className="form-control" value={val.formula_id} onChange={e => this.changeFormula_er(e, { key })}  >
                                                                    <option>Static Data</option>
                                                                    {/* {this.state.formula.map((valz, keyz) => {
                                                                          return (<option key={keyz} value={valz.id}>{valz.title}</option>);
                                                                      })} */}
                                                                       {frmla.map((valza, keyza) => {
                                                                      return (<option key={keyza} value={valza.id}>{valza.title}</option>);
                                                                  })} 
                                                                </select>
                                                            </td>

                                                            {/* <td>
                                                                <input name={val.id} className="form-control" value={val.amount} placeholder="This feild is formula testing purpose" onChange={e => this.onInputChange(e, { key })} />
                                                            </td> */}
                                                        </tr>
                                                    );
                                                })}

                                                <tr>
                                                <td style={{ background: "#948a9e", padding: "0.3rem", paddingLeft: '12px'}}>
                                                   <input
                                                    type="checkbox"
                                                    name="select_all"
                                                    checked={this.state.allcheckedDr}
                                                    onChange={(e) => this.sellectAll3(e)}
                                                    />
                                                    </td>
                                                    <td style={{ background: "#948a9e", padding: "0.3rem" }} colSpan="4"><h6 style={{ color: "#fff", 'font-weight': "bold" }}>Deduction Head</h6></td>
                                                </tr>

                                                {this.state.deduct_head.map((val, key) => {
                                                      let frml = val.formula;
                                                      var ck = Object.values(this.state.selectedHeadsDr).find(element => element == val.id)
                                                      var ck1 = false;
                                                      if (ck !== undefined) {
                                                        ck1 = true;
                                                      }
                                                    return (
                                                        <tr key={key}>
                                                            <td>
                                                            <input
                                                                type="checkbox"
                                                                name={val.id}
                                                                value={val.id}
                                                                checked={ck1}
                                                                onChange={(e) => this.onInputChangeDr(e)}
                                                                />
                                                            </td>
                                                            <td>{key + 1}</td>
                                                            <td>{val.name}  </td>
                                                            <td>
                                                                <select className="form-control" value={val.formula_id} onChange={e => this.changeFormula(e, { key })}  >
                                                                    <option>Static Data</option>
                                                                    {/* {this.state.formula.map((valz, keyz) => {
                                                                          return (<option key={keyz} value={valz.id}>{valz.title}</option>);
                                                                      })} */}
                                                                       {frml.map((valz, keyz) => {
                                                                          return (<option key={keyz} value={valz.id}>{valz.title}</option>);
                                                                      })} 
                                                                </select>
                                                            </td>
                                                            {/* <td>
                                                                <input name={val.id} className="form-control" value={val.amount} placeholder="This feild is formula testing purpose" onChange={e => this.onInputChange_deduct(e, { key })} />
                                                            </td> */}
                                                        </tr>
                                                    );
                                                })}

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" name="id" className="btn btn-primary mr-2">  Proceed </button>
                                        <Link style={{float:'right'}} className="btn btn-secondary"  to="/salary_template_list" >Back to List</Link>       

                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default withAlert()(Employee_salary_template_edit);