import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../services/Apiconnect.js";
import "../ems_component/pages/ems_pages.css";
import "../ems_component/pages/userstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import './public.css';
import Loadder from "./webview_lodder";


function Manual_attendance_request_approval(props) {
  const alert = useAlert();

  let id = props.match.params.id;

  const [loader, setLoader] = useState(false);

  useEffect(() => { attendaceData();}, []);
  const [attList, setAttList] = useState([]);

  const attendaceData = () => {
    setLoader(true)
      Apiconnect.getData(`employee_punchtime/attendancereportingdataById/${id}`).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setAttList(_xtract.reverse());
        setLoader(false)
     });
  }

  const attendancesApprove = e => {

    let info = { id: e, request_status: 1, approved_by: '' };
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };
    Apiconnect.postData("employee_punchtime/manual_attendance_updatereqstatus", inf_a).then((response) => {
       alert.success(response.data.message);
       attendaceData();
    });

 }

 const attendanceCancle = e => {
    let info = { id: e, request_status: 2, approved_by: '' };
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };
    Apiconnect.postData("employee_punchtime/manual_attendance_updatereqstatus", inf_a).then((response) => {
       alert.success(response.data.message);
       attendaceData();
    });
 }
  


  return (
    <>
  
      <div id="content-page" className="" style={{marginLeft:'unset !important',overflow: 'hidden',background: '#e2e2e2',padding: '20px 0px',minHeight: '100vh'}}>
        <div className="container-fluid leave_div">

        {
         loader ? <Loadder/>:
         <>
          <div className="row"  style={{fontSize:'13px'}}>
            
          <div className='="col-md-12 col-sm-12'>
            <div className="col-md-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Team Member Attendance</h4>
                  </div>
                </div>
                <div className="iq-card-body padLRTzero">

                  <div className="table-responsive">

                  <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                    <thead>
                                       <tr className="clr" >
                                          <th>#</th>
                                          <th>Emp. Name</th>
                                          <th>Punch Time</th>
                                          <th>Reason</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {attList.map((val, key) => {

                                          var action1 = '';
                                          if (val.request_status === 0) {
                                             var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                             var action = <div className="btn badge badge-pill badge-success" onClick={e => attendancesApprove(val.id)}>Approve</div>;
                                             var action1 = <div className="btn badge badge-pill badge-danger" onClick={e => attendanceCancle(val.id)}>Cancel</div>;
                                          }
                                          if (val.request_status === 1) {
                                             var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                          }
                                          if (val.request_status === 2) {
                                             var status = <div className=" badge badge-pill badge-danger">Cancelled</div>;
                                             var action = <div className="btn badge badge-pill badge-success" onClick={e => attendancesApprove(val.id)}>Approve</div>;
                                          }

                                          return (
                                             <tr key={key}>
                                                <th scope="row">{key + 1}</th>
                                                <td>{val.first_name} {val.last_name}</td>
                                                <td>{val.punch_time}</td>
                                                <td>{val.remark}</td>
                                                <td>{status}</td>
                                                <td>{action} {action1}</td>
                                             </tr>
                                          );
                                       })}
                                    </tbody>
                                 </table>
                  </div>




                </div>
              </div>
            </div>
          </div>
           
          </div>




         
          </>
          }
        </div>
      </div>

  
    </>
  )
}

export default Manual_attendance_request_approval
