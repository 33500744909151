import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import font from "../../fonts/arial/arial.ttf";
import fontBold from "../../fonts/arial/arial-bold.ttf"
var JsBarcode = require('jsbarcode');

// Font.register({
//   family: 'Arial',
//   fonts: [
//     {
//       src: `../../fonts/arial/arial.ttf`
//     }
//   ]
// })


Font.register({
  family: 'Arial',
  fonts: [
    {
      src: font
    },
    {
      src: fontBold,
      fontWeight: 'bold'
    }
  ]
})


function IdCard_view(props) {
  let history = useHistory();
  const local_user = JSON.parse(localStorage.getItem('user'));
  const [empListAll, setEmpListAll] = useState(props.data);
  const [companyData, setCompanyData] = useState(props.companyData);

  useEffect(() => {
    loadData()
  }, []);


  const loadData = async () => {
    const arr = [];
    for (let i = 0; i < empListAll.length; i++) {

      if (empListAll[i].display_picture == null || empListAll[i].display_picture == '') {

        if (empListAll[i].gender === 'Male') {
          var profile_img = "/images/user/1.jpg";
          empListAll[i]['display_picture'] = profile_img
        } else {
          var profile_img = "/images/user/2.jpg";
          empListAll[i]['display_picture'] = profile_img
        }
      }
      arr.push(empListAll[i])
      let barcode = await textToBase64Barcode(empListAll[i].emp_code)
      empListAll[i]['barcode'] = barcode
      if (empListAll.length - 1 === i) {
        setEmpListAll([...arr])
      }
    }
  }

  function textToBase64Barcode(text) {
    var canvas = document.createElement("canvas");
    JsBarcode(canvas, text, { displayValue: false, width: 1, height: 40, margin: 0, background: '#ffffff' });
    return canvas.toDataURL("image/png");
  }

  const styles = StyleSheet.create({
    page: {
      width: '100%',
      // marginBottom: '20px',
      // margin: '25px',
      fontFamily: 'Arial'
    
    },
    idcard: {
      width: '55mm', height: '85mm', padding: '10px 15px', position: 'relative'
    },
    pd: {
      textAlign: 'center', paddingBottom: '10px',
    },
    pdtext: {
      fontSize: '10px', color: 'brown', fontWeight: 'bold', textDecoration: 'underline', textUnderlineOffset: '8px'
    },
    tr: {
      flexDirection: "row",
      alignItems: "center",
      width: '100%',
      paddingTop: '2px', paddingBottom: '2px'

    },
    c1: {
      fontSize: '7px', width: '37%', verticalAlign: 'top', wordBreak: 'no-wrap',fontWeight: 'bold'
    },
    c2: {
      fontSize: '7px', width: '5%', verticalAlign: 'top',fontWeight: 'bold'
    },
    c3: {
      fontSize: '7px', width: '58%', verticalAlign: 'top', wordBreak: 'break-all',fontWeight: 'bold'

    }

  });

  const breakword = (email) => {

    let result = email.substr(0, 19);
    let result1 = email.substr(19, 37);

    return result + ' ' + result1;

  }

  function removeLastWord(str) {
    const lastIndexOfSpace = str.lastIndexOf(' ');
  
    if (lastIndexOfSpace === -1) {
      return str;
    }
  
    return str.substring(0, lastIndexOfSpace);
  }
  return (
    <>


      <Document>

        {
          empListAll.map((val, key) => {

            return (
              <>
                <Page style={styles.page} size={[157.35, 245.45]} key={key}>
                  <View style={styles.idcard}>
                    <View style={{ backgroundColor: '#f0f8ff61' }}>
                      <View style={{ width: '100%', display: 'flex', flexDirection: "row" }}>
                        <View style={{ width: '25%' }}>
                          <Image style={{ width: '100%' }} src={props.logo} />
                        </View>
                        <View style={{ width: '75%', textAlign: 'center', margin: 'auto' }}>
                          <Text style={{ fontSize: '8',fontWeight: 'bold', color: 'blue' }}>{companyData[0].company_name.split(' ').slice(0, 2).join(' ').toUpperCase()}</Text>
                          <Text style={{ fontSize: '10', fontWeight: 'bold', color: 'blue', fontFamily: 'Arial' }}>{companyData[0].company_name.split(' ').slice(2, 4).join(' ').toUpperCase()}</Text>
                        </View>
                      </View>
                      <View style={{ width: '100%', margin: '5px 0px' }}>
                        <Text
                          style={{
                            backgroundColor: '#edb415',
                            fontSize: '7',
                            letterSpacing: '0.5px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            paddingTop:'2px',
                            paddingBottom:'2px'
                          }}>
                          EMPOWER <span style={{margin: 'auto'}}>.</span> ENTHUSE <span style={{margin: 'auto'}}>.</span> EXCEL
                        </Text>
                        <Text
                          style={{
                            fontSize: '8',
                            textAlign: 'center',
                            paddingTop: '3px',
                            fontWeight: 'bold'
                          }}>
                          {companyData[0].landmark.toUpperCase()}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        width: '100%',
                        display: 'flex',
                        marginTop: '15px',
                        height: '35%',
                        flexDirection: "row",
                      }}>
                      <View style={{ width: '40%' }}>
                        <Image style={{ width: '85px',transform: 'rotate(90deg)', marginTop: '20px', marginLeft: '-20' }} src={val.barcode} />
                      </View>
                      <View style={{ width: '60%' }}>
                        <Image style={{ width: '75px', height: '75px', marginLeft: '-8px', marginTop: '-10px' }} src={val.display_picture} />
                      </View>
                    </View>
                    <View style={{ width: '100%' }}>
                      <Text
                        style={{
                          textAlign: 'center',
                          fontSize: '10px',
                          fontWeight: 'bold',
                          marginTop: '2px',
                          color: 'black'
                        }}>
                        {(val.first_name + ' ' + val.last_name).toUpperCase()}
                      </Text>
                      <Text
                        style={{
                          textAlign: 'center',
                          fontSize: '10px',
                          fontWeight: 'bold', margin: '2px'
                        }}>
                        {val.emp_designation}
                      </Text>
                      <Text
                        style={{
                          textAlign: 'center',
                          fontSize: '10px',
                          fontWeight: 'bold',
                          margin: '2px'
                        }}>
                        EMP. ID.: {val.emp_code}
                      </Text>
                      <Text
                        style={{
                          textAlign: 'center',
                          fontSize: '10px',
                          fontWeight: 'bold',
                        }}>
                        {val.department}
                      </Text>
                    </View>
                    {/* <View style={{ width: '100%', marginTop: '10px' }}>
                      <Text style={{
                        backgroundColor: '#ffd400c7',
                        height: '9px'
                      }}>
                      </Text>
                      <Text style={{
                        backgroundColor: 'blue',
                        height: '11px'
                      }}>
                      </Text>
                    </View> */}
                  </View>

                  <View break style={styles.idcard}>
                    {/* <View style={styles.pd}>
                      <Text style={styles.pdtext}>PERSONAL DETAIL</Text>
                    </View> */}
                    <View style={styles.tr}>
                      <View style={styles.c1}><Text>Phone No</Text></View>
                      <View style={styles.c2}><Text>:</Text></View>
                      <View style={styles.c3}><Text>{val.phone}</Text></View>
                    </View>
                    <View style={styles.tr}>
                      <View style={styles.c1}><Text>Emergency No</Text></View>
                      <View style={styles.c2}><Text>:</Text></View>
                      <View style={styles.c3}><Text>{val.emergency_contact_number}</Text></View>
                    </View>
                    <View style={styles.tr}>
                      <View style={styles.c1}><Text>Email ID.</Text></View>
                      <View style={styles.c2}><Text>:</Text></View>
                      <View style={styles.c3}><Text style={{ display: 'flex', flexDirection: 'row' }}>{breakword(val.email)}</Text></View>
                    </View>
                    <View style={styles.tr}>
                      <View style={styles.c1}><Text>Blood Group</Text></View>
                      <View style={styles.c2}><Text>:</Text></View>
                      <View style={styles.c3}><Text>{val.blood_group}</Text></View>
                    </View>
                    <View style={styles.tr}>
                      <View style={styles.c1}><Text>Address</Text></View>
                      <View style={styles.c2}><Text>:</Text></View>
                      <View style={{ width: '58%', verticalAlign: 'top',fontWeight: 'bold', fontSize: val.present_address_one && val.present_address_one.length > 92 ? '7px' : val.present_address_one && val.present_address_one.length > 102 ? '7px' :'7px' }}>
                        <Text style={{wordBreak: 'break-word',fontWeight: 'bold'}}>{val.present_address_one}</Text>
                      </View>
                    </View>
                    <View style={{ position: 'absolute', bottom:6, left: '16px', textAlign: 'center', margin: 'auto' }}>
                      <View
                        style={{
                          borderBottomColor: '0c6bbf',
                          borderBottomWidth: 0.6,
                        }}
                      />

                      <View style={{ padding: '3px', left: '50%' }}>
                        <Image style={{ width: '30%', left: '10%' }} src={'/images/principal/' + companyData[0].id + '.png'} />
                        <Text style={{ color: 'black', fontSize: '7px', fontWeight: 'bold', textAlign: 'right',right:'15px' }}>Authorized Signatory</Text>
                      </View>

                      <View style={{
                        border: '1px solid black',
                        padding: '0px 3px 5px 3px',
                        minWidth:'125px'

                      }}>
                        <Text style={{ color: 'black', fontSize: '7px', fontWeight: 'bold', paddingTop: '3px', paddingLeft: '10px', paddingRight: '10px', margin: 'auto' }}>{removeLastWord(companyData[0].address_one)}</Text>
                        <Text style={{ color: 'black', fontSize: '6.5px', paddingTop: '2px', fontWeight: 'bold', margin: 'auto', marginTop: '7px' }}>{companyData[0].address_two}</Text>
                        <Text style={{ color: 'black', fontSize: '6.5px', paddingTop: '2px', fontWeight: 'bold', margin: 'auto' }}>{companyData[0].phone}</Text>
                        <Text style={{ color: 'black', fontSize: '6px', paddingTop: '2px', fontWeight: 'bold', margin: 'auto' }}>{companyData[0].website}</Text>
                      </View>
                    </View>
                  </View>
                </Page>
              </>
            );
          })}
      </Document>

    </>
  )
}

export default React.memo(IdCard_view);