import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../services/Apiconnect.js";
import "../ems_component/pages/ems_pages.css";
import "../ems_component/pages/userstyle.css";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Helper from "../services/HelperCodebase.js";
import './public.css';
import Loadder from "./webview_lodder";


function Webview_leave(props) {
  const alert = useAlert();
  let history = useHistory();

//   const localuser = JSON.parse(localStorage.getItem('user'));
//   let emp = Apiconnect.decrypt(localuser.id);
  let emp = props.match.params.id;
  let company_id = props.match.params.company_id;


  const [loader, setLoader] = useState(false);

  const [info, setInfo] = useState({
    leave_typ: "", reason: "", company_id: company_id,
    id: null,
    emp_id: emp,
    typ: 'debit',
    status: 0,
    day_typ:''
  });
  const { leave_typ, reason, id,day_typ } = info;

  const [infoz, setInfoz] = useState({
    // creadited_leaves:0, 
    // debited_leaves:0,
    // carryforward_leaves:0,
    fromDate: new Date(),
    toDate: new Date(),
    mxDate: new Date(),
  });
  const { fromDate, toDate, mxDate } = infoz;

  const [creadited_leaves, setcreadited_leaves] = useState(0);
  const [debited_leaves, setdebited_leaves] = useState(0);
  const [carryforward_leaves, setcarryforward_leaves] = useState(0);
  const [leavestypelist, setleavestypelist] = useState([]);
  const [leaveTypeDay, setleaveTypeDay] = useState(true);



  const onInputChange = e => {
    setInfo({ ...info, [e.target.name]: e.target.value });

    if (e.target.name == 'leave_typ') {
      //console.log(e.target.name);
      //console.log(e.target.value);
      var typid = e.target.value;
      var this_typ = Helper.array_search_multidim('id', typid, List);

      var min_days = this_typ[0].leave_apply_days_min;
      var max_days = this_typ[0].leave_apply_days_max;

      var tx = parseInt(min_days) + parseInt(max_days);

      var myminDate = Helper.add_days(new Date(), min_days);
      var mymaxDate = Helper.add_days(new Date(), tx);
      setInfoz({
        ...infoz,
        fromDate: new Date(myminDate),
        toDate: new Date(myminDate),
        mxDate: new Date(mymaxDate),

      });




    }


  };
  const fromDateChage = async e => {
    var mymaxDate = Helper.add_days(e, 10);
    setInfoz({
      ...infoz,
      fromDate: e,
      toDate: e,
      mxDate: new Date(mymaxDate),
    });
  }
  const toDateChage = async e => {
    setInfoz({ ...infoz, toDate: e });
  }

  const [List, setleaveType] = useState([]);
  const [leaveList, setleaveList] = useState([]);
  const [reportor, setReportorList] = useState({ first_name: '', last_name: '' });
  const { first_name, last_name } = reportor;



  useEffect(() => {
    leaveType();
    getReportor();
    // getCreditedLeave();
    // getdebitedLeave();
    // getCarryforwardLeave();
    getLeaveByType();
    getLeaveList();
  }, []);





  const leaveType = () => {
    Apiconnect.getData(`public/company_leave_policy/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleaveType(_xtract.reverse());
    });


  }
  const getLeaveList = () => {
    setLoader(true);
    Apiconnect.getData(`public/employeeleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleaveList(_xtract.reverse());
       setLoader(false);
    });
  }
  const getReportor = () => {
    Apiconnect.getData(`public/leaveapprovalauthority/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setReportorList({ first_name: _xtract[0].first_name, last_name: _xtract[0].last_name });
    });
  }

  //////////////----------------Leave summary------------------///////////////////////

  const getLeaveByType = () => {
    Apiconnect.getData(`public/getAllLeaveByType/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setleavestypelist(_xtract.reverse());
    });

    Apiconnect.getData(`public/totalcreditedleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].creditedleaves !== null) {
        setcreadited_leaves(_xtract[0].creditedleaves);
      }
    });

    Apiconnect.getData(`public/totaltakenleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].debitleaves !== null) {
        setdebited_leaves(_xtract[0].debitleaves);
      }
    });

    Apiconnect.getData(`public/totalcarryforwardleave/${emp}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if (_xtract[0].carryforwardleaves !== null) {
        setcarryforward_leaves(_xtract[0].carryforwardleaves);
      }
    });

  }

  const onSubmit = async e => {
    e.preventDefault();

    await Apiconnect.getData(`public/getLeaveByTypeId/${emp}/${info.leave_typ}`,).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      let _xtract1 = Apiconnect.decrypt_obj(response.data.data1);
      let _xtract2 = Apiconnect.decrypt_obj(response.data.data2);

      if (_xtract[0].credit_cnt !== null) {
        var creadited_leaves1 = _xtract[0].credit_cnt;
      } else {
        var creadited_leaves1 = 0;
      }
      if (_xtract1[0].debit_cnt !== null) {
        var debited_leaves1 = _xtract1[0].debit_cnt;
      } else {
        var debited_leaves1 = 0;
      }
      if (_xtract2[0].carryforward_cnt !== null) {
        var carryforward_leaves1 = _xtract2[0].carryforward_cnt;
      } else {
        var carryforward_leaves1 = 0;
      }


      console.log('in add function');
      info.leave_start_date = format(fromDate, 'yyyy-MM-dd');
      info.leave_end_date = format(toDate, 'yyyy-MM-dd');

      var Difference_In_Time = toDate.getTime() - fromDate.getTime();
      var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
      var Final_Result = Difference_In_Days.toFixed(0);
      info.leave_count = Number(Final_Result) + 1;

      const available_leave = carryforward_leaves1 + creadited_leaves1 - debited_leaves1;
      const lop = Number(available_leave) - (Number(Final_Result) + 1);

      if (lop < 0) {
        info.lop = Math.abs(lop);
      } else {
        info.lop = 0;
      }

      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc,user_id:emp };
      Apiconnect.postData("public/create_by_empid", inf_a).then((response) => {
        // console.log(response);
        history.push(`${emp}`);
        setInfo({
          leave_typ: "", reason: "", id: '', company_id: company_id,
          emp_id: emp, typ: 'debit',day_typ:''
        });
        alert.success(response.data.message);
        // console.log(response);
        getLeaveList();
        getLeaveByType();
      });

    })

  }

  const onDatepickerRef = el => { 
     if (el && el.input) { el.input.readOnly = true; }
   }

   const selectType = (type) => {
    if(type == 'full_day'){
      setleaveTypeDay(true)
    }
    if(type == 'half_day'){
      setleaveTypeDay(false)
    }
  }

  return (
    <>
  
      <div id="content-page" className="" style={{marginLeft:'unset !important',overflow: 'hidden',background: '#e2e2e2',padding: '20px 0px',minHeight: '100vh'}}>
        <div className="container-fluid leave_div">

        {
         loader ? <Loadder/>:
         <>
          <div className="row " >
            <div className="col-md-6">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between" style={{minHeight:'0px'}}>
                  {/* <div className="iq-header-title">
                    <h4 className="card-title">Apply leave</h4>
                  </div> */}
                </div>
                <div className="iq-card-body">
                  <form onSubmit={e => onSubmit(e)}>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label>Leave Type*</label>
                        <select className="form-control" name="leave_typ" required
                          value={leave_typ}
                          onChange={e => onInputChange(e)}>
                          <option value="">Select Leave Type</option>
                          {List.map((val, key) => {
                            return (
                              <option key={key} value={val.id}>{val.name}</option>
                            );
                          })}
                        </select>

                      </div>

                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                      <div class="form-check form-check-inline" >
                        <input onClick={()=>selectType('full_day')} onChange={(e) => onInputChange(e)} class="form-check-input" type="radio" name="is_halfday" id="inlineRadio1" value="0"/>
                        <label class="form-check-label" for="inlineRadio1">Full Day</label>
                      </div>
                      <div class="form-check form-check-inline" >
                        <input onClick={()=>selectType('half_day')} onChange={(e) => onInputChange(e)} class="form-check-input" type="radio" name="is_halfday" id="inlineRadio2" value="1"/>
                        <label class="form-check-label" for="inlineRadio2">Half Day</label>
                      </div>
                      </div>
                    </div>

                    <div className="form-group row">

                      <div className="col-sm-12">
                        <label>From*</label>
                        <DatePicker

                          onChange={e => fromDateChage(e)}
                          //onChange={e => setfromDate(e)}
                          ref={el => onDatepickerRef(el)}
                          className="form-control"
                          dateFormat='yyyy-MM-dd'
                          placeholderText="From Date"
                          selected={fromDate}
                          minDate={fromDate}
                          // maxDate={mxDate}
                          name="from_dt"
                          style={{display:'unset !important'}}
                        />
                      </div>
                      </div>
                      <div className="form-group row">

                      {/* <div className="col-sm-12">
                        <label>To*</label>
                        <DatePicker
                          selected={toDate}
                          minDate={fromDate}
                          // maxDate={fromDate}
                          ref={el => onDatepickerRef(el)}
                          onChange={e => toDateChage(e)}
                          className="form-control"
                          dateFormat='yyyy-MM-dd'
                          placeholderText="To Date"
                          name="to_dt"
                        />
                      </div> */}

                      <div className="col-sm-6" style={{display:leaveTypeDay?'':'none'}}>
                        <label>To*</label>
                        <DatePicker
                          selected={toDate}
                          minDate={toDate}
                          // maxDate={mxDate}
                          onChange={(e) => toDateChage(e)}
                          className="form-control"
                          dateFormat="yyyy-MM-dd"
                          placeholderText="To Date"
                          name="to_dt"
                        />
                      </div>

                      <div className="col-sm-6" style={{display:leaveTypeDay?'none':''}}>
                        <label>Half Day*</label>
                        <select
                          className="form-control"
                          name="day_typ"
                          required={leaveTypeDay?'':"reqired"}
                          value={day_typ}
                          onChange={(e) => onInputChange(e)}
                        >
                          <option value="">Select Type</option>
                          <option value="1st Half">1st Half</option>
                          <option value="2nd Half">2st Half</option>
                          
                        </select>
                      </div>


                    </div>
                    <div className="form-group row">

                      <div className="col-sm-12">
                        <label>Reason*</label>
                        <textarea className="form-control" name="reason" value={reason} onChange={e => onInputChange(e)} required></textarea>
                      </div>
                    </div>



                    <div className="form-group row" style={{textAlign: 'center',paddingTop:'10px'}}>
                      <div className="col-sm-12"><button type="submit" className="btn btn-primary btn-block" style={{background: '#4f4967',border: '#4f4958'}}>Submit</button></div>

                    </div>
                  </form>
                </div>
              </div>
            </div>


            <div className="col-md-6">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Leave summary</h4>
                  </div>
                </div>
                <div className="iq-card-body">




                  <div className="row">
                    <div className="col-md-6">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themeprimary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themeprimary">{creadited_leaves + carryforward_leaves}</span>
                          <div className="databox-text darkgray">
                            Total Leave Credited</div>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themesecondary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themesecondary">{debited_leaves}</span>
                          <div className="databox-text darkgray">
                            Total Leave Taken</div>

                        </div>
                      </div>
                    </div>

                    <div className="col-md-12" style={{textAlign: 'center',marginBottom:'20px'}}>
                      <table style={{width:'100%',border: '1px solid #a3a3a3'}}>
                        <tr style={{border: '1px solid #a3a3a3',backgroundColor: '#51445f',color: 'white'}}>
                          <th style={{border: '1px solid #a3a3a3',width:'10%'}}>#</th>
                          <th style={{border: '1px solid #a3a3a3',width:'30%'}}>Leave Type</th>
                          <th style={{border: '1px solid #a3a3a3',width:'30%'}}>LOP</th>
                          <th style={{border: '1px solid #a3a3a3',width:'30%'}}>Total Available Till Now</th>
                        </tr>
                        {
                          leavestypelist.map((v,k)=>{
                            var total_till_now = Number(v.carryforward_cnt) + Number(v.credit_cnt) - Number(v.debit_cnt);
                            return(
                              <tr style={{border: '1px solid #a3a3a3'}}>
                                <th style={{border: '1px solid #a3a3a3'}}>{k+1}</th>
                                <th style={{border: '1px solid #a3a3a3'}}>{v.name}</th>
                                <th style={{border: '1px solid #a3a3a3',backgroundColor: '#fb6e52'}}>{Number(v.lop_cnt)}</th>
                                <th style={{border: '1px solid #a3a3a3',backgroundColor: '#a0d468'}}>{total_till_now > 0 ?total_till_now:0}</th>
                              </tr>
                            );
                          })
                          
                        }
                      </table>
                    </div>
                    <div className="col-md-12">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themefourthcolor">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-calendar"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themefourthcolor">
                            {carryforward_leaves}</span>
                          <div className="databox-text darkgray">
                            Carry forward from last year</div>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="databox bg-white radius-bordered">
                        <div className="databox-left bg-themeprimary">
                          <div className="databox-piechart">
                            <i className="font40 fa fa-user"></i>
                          </div>
                        </div>
                        <div className="databox-right">
                          <span className="databox-number themeprimary">
                            {first_name} {last_name}
                          </span>
                          <div className="databox-text darkgray">
                            Approval Authority</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>




          <div className="row" >
            <div className="col-md-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">List of leaves</h4>
                  </div>
                </div>
                <div className="iq-card-body padLRTzero">

                  <div className="table-responsive">

                    <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                      <thead>
                        <tr className="clr">
                          <th>#</th>
                          <th>Applied On</th>
                          <th>Leave Type</th>
                          <th>Reason</th>
                          <th>Days</th>
                          <th>Half Day Type</th>
                          <th>Date</th>
                          <th>Opening</th>
                          <th>Available</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>

                        {leaveList.map((val, key) => {

                          if (val.status == 0) {
                            var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                          }
                          if (val.status == 1) {
                            var status = <div className=" badge badge-pill badge-success">Approved</div>;
                          }
                          if (val.status == 2) {
                            var status = <div className=" badge badge-pill badge-danger">Cancelled</div>;
                          }

                          return (
                            <tr key={key}>
                              <th scope="row">{key + 1}</th>
                              <td>{val.applied_on}</td>
                              <td>{val.name}</td>
                              <td>{val.reason}</td>
                              <td>{val.leave_count}</td>
                              <td style={{textAlign:'center'}}>{val.day_typ ? val.day_typ:'-'}</td>
                              <td>{val.leave_start_date1} to {val.leave_end_date1}</td>
                              <td></td>
                              <td></td>
                              <td>
                                {status}
                                {/* <div className=" badge badge-pill badge-success">Approved</div>
                                             <div className=" badge badge-pill badge-danger">Cancelled</div>
                                             <div className=" badge badge-pill badge-warning">Pending</div> */}
                              </td>
                            </tr>
                          );
                        })}

                      </tbody>
                    </table>
                  </div>




                </div>
              </div>
            </div>
          </div>
          </>
          }
        </div>
      </div>

  
    </>
  )
}

export default Webview_leave
