import React, {useState, useEffect } from 'react';
import "./ems_pages.css";
 
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import Modal from 'react-modal';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function Daily_attendance() {

    const alert = useAlert();
    let history = useHistory();

    const [from_date, setFromDate] = useState(new Date());
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id); 

    useEffect(() =>  {getDailyData(from_date)},[]);

    const [info, setInfo] = useState({
        emp_id: emp, 
        company_id:Apiconnect.get_company_id(),       
      });
    const { emp_id, company_id  } = info;
    

        
    const markAttendance = async e => {
        e.preventDefault(); 
        console.log(info);
         
        let info_enc =  Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
    
        await Apiconnect.postData("employee_punchtime/attendance/",inf_a).then((response) => {
            console.log(response);             
            alert.success(response.data.message);   
         });
      };

    

      const [catList, setCatList] = useState([]); 
      const [dailyAttendanceList, setDailyAttendanceList] = useState([]); 

      const punchtime = () => {
         Apiconnect.getData(`employee_punchtime/getinfo/${emp}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setCatList(_xtract.reverse());      
         });
      };

      const getDailyData = (from_date) => {
        if (from_date != null) {
            var from = format(from_date, "yyyy-MM");
          }
        Apiconnect.getData(`employee_dailydata/getinfo/${emp}/${from}`).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
        //    setDailyAttendanceList(_xtract); 
           console.log('date',from,format(new Date(), "yyyy-MM") ) 
           console.log('_xtract',_xtract)   
           
           if(format(new Date(), "yyyy-MM") === from){
            var start = moment().format("D");
           }else{
            var start = moment(from_date).daysInMonth();
           }
        
        const output= [];
        for (var i = start; i >= 1; i--) {
            let isDailyData = _xtract.find(val => parseInt(val.days )=== i)
            if(isDailyData){
                output.push(isDailyData)
            }else{
                var obj = {
                    break_hours1: "00:00",
                    days:i,
                    emp_id: emp,
                    end_time: "-",
                    id: '',
                    production_hour1: "00:00",
                    punch_date1: moment(i,'Do').format('Do') +' '+moment(from_date).format("MMMM")+' '+moment(from_date).format("Y"),
                    start_time: "-",
                }
                output.push(obj)
            }
           
        }
        setDailyAttendanceList(output);  
        });

       
     };


     const onChangeDate = (date)=>{
        setFromDate(date)
        getDailyData(date)
     }
  


    return (
        <>

            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            {/* <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Manual Attendance</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <form onSubmit={e => markAttendance(e)}>
                                        <div className="row">
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button  type="submit" className="btn btn-primary">Mark Attendance</button>
                                                 
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> */}
{/*                         
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Latest  attendance</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body padLRTzero">

                                    <div className="table-responsive">
                            
                                        <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                                            <thead>
                                                <tr className="clr">
                                                    <th>#</th>                                     
                                                    <th>Date</th>                                    
                                                    <th>Punch Time</th>                                    
                                                                                       
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {catList.map((val, key) => {       
                                                    return (
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{val.punch_dt}</td>
                                                        <td>{val.punch_tm}</td>                                                                         
                                                        
                                                    </tr>
                                                    );
                                                })}  
                                                

                                                
                                            </tbody>
                                        </table>
                                    
                                    
                                    
                                    </div>
                                     
                                </div>
                            </div> */}
                        
                        
                        
                        
                        
                        </div> 


                        <div className="col-md-12">
                                <div className="iq-card">
                                        <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Your Attendance</h4>
                                        </div>
                                        </div>
                                        <div className="row" style={{margin:'10px'}}>
                                        <div className="col-md-3">
                                        Months :
                                        <DatePicker
                                            selected={from_date}
                                            onChange={(date) => onChangeDate(date)}
                                            maxDate={new Date()}
                                            dateFormat="MM/yyyy"
                                            className="form-control"
                                            showMonthYearPicker
                                        />
                                        </div>
                                        </div>

                                    <div className="iq-card-body padLRTzero">
                                    
                                        <div className="table-responsive">
                                        
                                        <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                                            <thead>
                                                <tr className="clr">
                                                    <th>#</th>
                                                    <th>Date</th>
                                                    <th>In Time</th>
                                                    <th>Out Time</th>
                                                    <th>Break Hours</th>
                                                    <th>Total Hours</th>                                    
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>

                                            {dailyAttendanceList.map((val, key) => {      
                                                
                                                
                                                if (val.production_hour1 >= '08:00') {
                                                    var production_hour = <div className=" badge badge-pill badge-success">{val.production_hour1}</div>;
                                                 }else if(val.production_hour1 <= '08:00' && val.production_hour1 >= '04:00'){
                                                    var production_hour = <div className=" badge badge-pill badge-warning">{val.production_hour1}</div>;
                                                 }else{
                                                    var production_hour = <div className=" badge badge-pill badge-danger">{val.production_hour1}</div>;
                                                 }

                                                    return (
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{val.punch_date1}</td>
                                                        <td>{val.start_time}</td>
                                                        <td>{val.end_time}</td>
                                                        <td>{val.break_hours1}</td>
                                                        <td>{production_hour}</td>                                                                         
                                                        
                                                    </tr>
                                                    );
                                                })}  
                                                
                                            </tbody>
                                        </table>
                                        </div>
                                    
                                    
                                    

                                    </div>
                                </div>
                        
                        </div>
                    </div>





 









                </div>
            </div>



        </>
    )
}

export default Daily_attendance
