import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import FullScreenLoading from 'react-fullscreen-loading';

function Daily_late_arrival_report() {
    const alert = useAlert();

    const [from_date, setFromDate] = useState(null);
    const [emp_id, setempid] = useState(null);
    const [dwnEmpAttendanceList, setDwnEmpAttendanceList] = useState([]);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

 
    useEffect(() => { empdata();  }, []);
    const [empList, setEmpList] = useState([]);
    const [dailyAttendanceList, setDailyAttendanceList] = useState([]);


    const empdata = () => {
        Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpList(_xtract.reverse());
        });
    }

    const submit = () => {
       
        if (from_date != null) {
            var from = format(from_date, 'yyyy-MM-dd');
        }else{
            alert.error('Select from date');
            return
        }

        setFullScreenLoader('true');
        let info = { company_id: Apiconnect.get_company_id(), from_date: from, emp_id: emp_id};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        Apiconnect.postData(`employee_dailydata/get_late_arrival_report`, inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setDailyAttendanceList(_xtract.reverse());
            setDwnEmpAttendanceList(_xtract.reverse());
            setFullScreenLoader(false);
            
        });
    }

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">

                {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    : null
                    }

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div>
                                    <h4 className="card-title hd">Daily Late Arrival Report</h4>
                                </div>
                                <div className="iq-card-body">

                                    <div className="row p_t_15">
                                        <div className="col-md-3">
                                            Date :  <br /><DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="Selete Date"
                                                name="from_date"
                                                required=""
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} onClick={e => submit(e)} />
                                        </div>        
                                    </div>

                                    <div className="row" style={{ marginTop: '25px' }} >

                                        <div className="col-lg-12">
                                            <div className="iq-card">
                                                <div className="row">
                                                    <div className="col-md-6" >
                                                    </div>

                                                    {
                                                        from_date && dwnEmpAttendanceList.length > 0
                                                        ? (
                                                            <div className="col-md-6" >
                                                            <CSVLink filename={format(from_date, 'yyyy-mm-dd')+"_Daily_Late_Arrival_Report.csv"} style={{ marginTop: '10px', marginRight: '20px', float: 'right' }} className="btn btn-warning" data={dwnEmpAttendanceList}  >
                                                                <i class="fa fa-download"></i>Daily Late Arrival Report
                                                            </CSVLink>
                                                        </div>
                                                        )
                                                        :''
                                                    }
                                                  
                                                </div>
                                                <div class="iq-card-body">
                                                    <div className="table-responsive">

                                                        <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                                                            <thead>
                                                                <tr className="clr">
                                                                    <th>#</th>
                                                                    <th>Department</th>
                                                                    <th>Emp Code</th>
                                                                    <th>Emp Name</th>
                                                                    <th>Designation</th>
                                                                    <th>Shift</th>
                                                                    <th>Shift Start Time</th>
                                                                    <th>In time</th>
                                                                    <th>Late Arrival</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {dailyAttendanceList.length > 0
                                                                    ?
                                                                    dailyAttendanceList.map((val, key) => {
                                                                    
                                                                    var start_time = '-';
                                                                    if (val.start_time != null) {
                                                                         start_time = val.start_time;
                                                                    }

                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{key + 1}</td>
                                                                            <td>{val.department}</td>
                                                                            <td>{val.emp_code}</td>
                                                                            <td>{val.Employee_name}</td>
                                                                            <td>{val.Designation}</td>
                                                                            <td>{val.shift_name}</td>
                                                                            <td>{val.shift_in_time}</td>
                                                                            <td>{start_time}</td>
                                                                            <td>{val.late_arrival}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            : <tr>
                                                                <th colSpan={11}>No Data Found</th>
                                                                </tr>}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )

}
export default Daily_late_arrival_report