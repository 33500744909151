import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';

function Team_member_attendance() {

   const alert = useAlert();
   const localuser = JSON.parse(localStorage.getItem('user'));
   let emp = Apiconnect.decrypt(localuser.id);



   const attendancesApprove = e => {

      let info = { id: e, request_status: 1, approved_by: emp };
      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc };
      Apiconnect.postData("employee_punchtime/manual_attendance_updatereqstatus", inf_a).then((response) => {
         alert.success(response.data.message);
         attendaceData();
      });

   }

   const attendanceCancle = e => {
      let info = { id: e, request_status: 2, approved_by: emp };
      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc };
      Apiconnect.postData("employee_punchtime/manual_attendance_updatereqstatus", inf_a).then((response) => {
         alert.success(response.data.message);
         attendaceData();
      });
   }

   useEffect(() => { attendaceData();}, []);
   const [leaveList, setleaveList] = useState([]);

   const attendaceData = () => {
       Apiconnect.getData(`employee_punchtime/attendancereportingdata/${emp}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         console.log('_xtract',_xtract)
         setleaveList(_xtract.reverse());
      });
   }

   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">


               <div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card">
                        <div>
                           <h4 className="card-title hd">Team Member Attendance</h4>
                        </div>
                        <div className="iq-card-body">
                           <div className="row">

                              <div className="table-responsive">

                                 <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                    <thead>
                                       <tr className="clr" >
                                          <th>#</th>
                                          <th>Emp. Name</th>
                                          <th>Punch Time</th>
                                          <th>Reason</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {leaveList.map((val, key) => {

                                          var action1 = '';
                                          if (val.request_status === 0) {
                                             var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                             var action = <div className="btn badge badge-pill badge-success" onClick={e => attendancesApprove(val.id)}>Approve</div>;
                                             var action1 = <div className="btn badge badge-pill badge-danger" onClick={e => attendanceCancle(val.id)}>Cancel</div>;
                                          }
                                          if (val.request_status === 1) {
                                             var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                          }
                                          if (val.request_status === 2) {
                                             var status = <div className=" badge badge-pill badge-danger">Cancelled</div>;
                                             var action = <div className="btn badge badge-pill badge-success" onClick={e => attendancesApprove(val.id)}>Approve</div>;
                                          }

                                          return (
                                             <tr key={key}>
                                                <th scope="row">{key + 1}</th>
                                                <td>{val.first_name} {val.last_name}</td>
                                                <td>{val.punch_time}</td>
                                                <td>{val.remark}</td>
                                                <td>{status}</td>
                                                <td>{action} {action1}</td>
                                             </tr>
                                          );
                                       })}
                                    </tbody>
                                 </table>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>


      </>
   )
}

export default Team_member_attendance
