import React, { useState, useEffect, Component } from 'react';
import { useHistory } from "react-router-dom";
import Apiconnect from "../services/Apiconnect.js";
// import "./ems_pages.css";
import moment from 'moment'
import { format } from 'date-fns';
import * as html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import Axios from 'axios';
import FullScreenLoading from 'react-fullscreen-loading';
import imageToBase64 from 'image-to-base64/browser';

function Salary_slip(props) {
    let history = useHistory();

    let emp_id = props.match.params.id;
    let year = props.match.params.year;
    let month = props.match.params.month;
    let months = Number(month) - 1;

   const local_user = JSON.parse(localStorage.getItem('user'));
    // function returnPage() {
    //     history.push("/ems/salary_slip");
    // }

    const [salaryMonth, setSalaryMonthList] = useState([]);
    const [deduction_tot, setDeduction_tot] = useState(0);
    const [earning_tot, setEarning_tot] = useState(0);
    const [leavestypelist, setleavestypelist] = useState([]);
    const [debited_leaves, setdebited_leaves] = useState(0);
    const [paidDay, setPaidDay] = useState(0);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [logo, setlogo] = useState(null);
    const [companyData,setAddress] = useState(null)
    const [earning_head, setEarning_head] = useState([]);
    const [deduct_head, setDeduct_head] = useState([])
    const [selectedHeads, setSelectedHeads] = useState([]);
    const [selectedHeadsDr, setSelectedHeadsDr] = useState([]);

    useEffect(() => {
         getsalaryMonthWise();
         getEmpInfo();
         getLeaveByType();
         getMonthlyPaidDay();
         getCompany();
         getdeductList();
         get_earning_head();
         }, []);
    const [info, setInfo] = useState({
        name: '', department: '', emp_code: '', account_number: '', bank_name: '', pan: '',esi_number:'',epf_number:'',joining_date:'',designation:'',aadhaar:'',branch_name:''
    });
    const { name, department, emp_code, account_number, bank_name, pan,epf_number,esi_number,joining_date,designation,aadhaar,branch_name } = info;

    const get_earning_head = () => {         
        Apiconnect.getData(`earning_head/getall/${Apiconnect.get_company_id()}`).then((response) => {
              let ern = Apiconnect.decrypt_obj(response.data.data);
              setEarning_head(ern)
            //   console.log(ern);
          });
      };
      const getdeductList = () => {
            //var l_user = JSON.parse(localStorage.getItem('user'));
            //var empid = l_user.emp_id;
          Apiconnect.getData(`deduction_head/getall/${Apiconnect.get_company_id()}`).then((response) => {           
              let _xtract = Apiconnect.decrypt_obj(response.data.data);
              setDeduct_head(_xtract)
          });
      };

    const getMonthlyPaidDay = () => {
        Apiconnect.getData(`employee_leave/getmonthlypaiddays/${emp_id}/${year}/${month}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setPaidDay(_xtract[0].present_days)

        });
    }
  
    const getEmpInfo = () =>{
        Apiconnect.getData(`employee_monthly_salary/getempsalaryinfo/${emp_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);


           if(_xtract.length > 0){
                setInfo({
                    name: _xtract[0].first_name + ' ' + _xtract[0].last_name, department: _xtract[0].department, emp_code: _xtract[0].emp_code,
                    account_number: _xtract[0].account_number, bank_name: _xtract[0].bank_name, pan: _xtract[0].pan,esi_number:_xtract[0].esi_number,
                    epf_number:_xtract[0].epf_number,joining_date:_xtract[0].joining_date,designation:_xtract[0].designation,aadhaar:_xtract[0].aadhaar,branch_name:_xtract[0].branch_name
                });
           }
        });
    }

 

    const getsalaryMonthWise = () => {
        setFullScreenLoader(true)
        const info = {};
        info.month = month;
        info.year = year;
        info.emp_id = emp_id;
         let info_enc =  Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
         Apiconnect.postData(`public/getsalarymonthwisepdf`,inf_a).then((response) => {
          //  Axios.post(`http://localhost:3050/api/public/getsalarymonthwisepdf`,inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            if(_xtract.length <= 0){
            }else{
             
                //  setEarning_tot(_xtract[0].earning_tot);
                // setDeduction_tot(_xtract[0].deduction_tot);
                setSalaryMonthList(_xtract);
                if(response.data.temp_data){
                    const erArr = response.data.temp_data[0].display_salary_er !== '' ? response.data.temp_data[0].display_salary_er.split(',') : [];
                    const drArr = response.data.temp_data[0].display_salary_dr !== '' ? response.data.temp_data[0].display_salary_dr.split(',') : [];
                    setSelectedHeads(erArr)
                    setSelectedHeadsDr(drArr);
                }
                   

            }
           
        });
    };

    useEffect(() => {
        var sumEr = 0  
             earning_head.map( val1 => {
                var data = salaryMonth.find(val => val.typ == 'earning' && val.earning_name == val1.name)
                var ishead = selectedHeads.includes(val1.id.toString())
                if(data && ishead){
                sumEr = sumEr+data.amount
                }
             })
         setEarning_tot(sumEr);
    },[earning_head,salaryMonth])

    useEffect(() => {
        var sumDr = 0  
             deduct_head.map( val1 => {
                var data = salaryMonth.find(val => val.typ == 'deduction' && val.deduction_name == val1.name)
                var ishead = selectedHeadsDr.includes(val1.id.toString())
                if(data && ishead){
                sumDr = sumDr+data.amount
                }
             })
             setDeduction_tot(sumDr);
    },[deduct_head,salaryMonth])

    const mn = [
        'JAN', 'FEB', 'MAR', 'APR',
        'MAY', 'JUN', 'JUL', 'AUG',
        'SEP', 'OCT', 'NOV', 'DEC'
    ]
    
 

    const getLeaveByType = () => {
        Apiconnect.getData(`employee_leave/getAllLeaveByType/${emp_id}`).then(
          (response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setleavestypelist(_xtract.reverse());
          }
        );

        Apiconnect.getData(`employee_leave/totaltakenleave/${emp_id}`).then(
            (response) => {
              let _xtract = Apiconnect.decrypt_obj(response.data.data);
              if (_xtract[0].debitleaves !== null) {
                setdebited_leaves(_xtract[0].debitleaves);
              }
            }
          );
      };

      const getCompany = () => {
        setFullScreenLoader(true);
        if (Apiconnect.get_company_id() != "") {
          Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then(
            (response) => {
              let _xtract = Apiconnect.decrypt_obj(response.data.data);
              setAddress(_xtract)
              if (_xtract[0].logo == null) {
                setlogo("images/logo.png");
                setFullScreenLoader(false);
              } else {
                //   console.log('image',response.data.logo)
                setlogo(response.data.logo);
                setFullScreenLoader(false);
              }
            }
          );
        } else {
          var img =
            process.env.React_App_BASE_URL_API + "/uploads/logo/school_logo.png";
          setlogo(img);
          setFullScreenLoader(false);
        }
      };

    var year1 = new Date().getFullYear();


    const generatePDF = e => {

        var doc = new jsPDF('p', 'pt','a3');
      
        doc.html(document.getElementById('print'), {
        callback: function (doc) {
            doc.save(name+' payslip_'+mn[months]+'.pdf');
        },
        x:35,
        y: 20,
        });
    }

    return (
        <>
                <div id="content-page" className="content-page">
                <div className="container-fluid">
                {fullScreenLoader
                ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                  )
                : null
                }
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="iq-card">
                                <div className="iq-card-body"  >
                                <div className="col-md-12 m-t-20" style={{paddingBottom:'30px'}}>
                                        <button  className="btn btn-success mr-2" onClick={e=>generatePDF(e)} style={{cursor: 'pointer',float: 'right'}}><i className="fa fa-download" aria-hidden="true" ></i> Download</button>
                                    </div>
                                    <div className="container mt-5 mb-5" style={{'backgroundColor': 'unset'}}>
                                        <div className="row" id="print" style={{ width: '85%'}}>
                                        <div  style={{ border: '1px solid black',fontSize:'10px'   }}>
                                            <div className="col-sm-12 col-lg-12" style={{marginBottom: '10px',borderBottom:'1px solid black' }}>
                                            <div className="row" >
                                                <div className="col-md-3" style={{padding: '10px'}}>
                                                    <img style={{height:'120px',marginLeft: '30px'}} src={logo} className="img-fluid" alt="" />
                                                </div>
                                                <div className="text-center col-md-6" style={{padding: '10px'}}>
                                                <h5 style={{ marginBottom: '1px',fontSize:'16px'}}><b> {local_user.company_name}</b></h5>
                                                <span className="fw-normal">{companyData ? companyData[0].address_one : ''}</span> <br/>
                                                <span className="fw-normal">{companyData ? companyData[0].address_two : ''}</span> <br/>
                                                <span className="fw-normal">{companyData ? companyData[0].website +' / '+ companyData[0].phone : ''}</span>
                                                   <div className="text-center lh-1 mb-2">
                                                      <h6 style={{marginTop:'10px',marginBottom:'3px'}} className="fw-bold">Payslip</h6> <span className="fw-normal">Payment slip for the month of <b>{mn[months]} {year}</b></span>
                                                   </div>
                                                </div>
                                                <div className="col-md-3">
                                                </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">

                                                {/* <div className="d-flex justify-content-end"> <span>Working Branch:ROHINI</span> </div> */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                        <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>EMP Name :</b> </span>{name} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Bank Name :</b> </span> {bank_name} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>ESI No. :</b> </span> {esi_number} </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>EMP Code :</b> </span> {emp_code} </div>
                                                            </div>
                                                           
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>Bank A/C no. :</b> </span> {account_number} </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>PF/UAN No :</b> </span> {epf_number} </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                            <div><b>Total Paid Days :</b> <span>{paidDay}</span></div>
                                                                {/* <div> <span className="fw-bolder"><b>Department :</b> </span> {department} </div> */}
                                                            </div>
                                                            <div className="col-md-4">
                                                                {/* <div> <span className="fw-bolder"><b>Designation :</b> </span> {designation}  </div> */}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>PAN No :</b> </span> {pan}  </div>
                                                            </div>
                                                           
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                {/* <div> <span className="fw-bolder"><b>DOJ :</b> </span> {joining_date} </div> */}
                                                            </div>
                                                            <div className="col-md-4">
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div> <span className="fw-bolder"><b>AADHAR No :</b> </span> {aadhaar} </div>
                                                            </div>
                                                           
                                                           
                                                        </div>
                                                        
                                                    </div>

                                                    <div className="col-md-12" style={{marginTop:'10px'}}>
                                                        <div className="col-md-6" style={{paddingLeft:'0px'}}>
                                                            {/* <div><b>Total Paid Days :</b> <span>{paidDay}</span></div> */}
                                                         </div>
                                                        <div className="col-md-6" style={{float:'right'}}>
                                                        <table
                                                            style={{ width: "100%", border: "1px solid #a3a3a3",textAlign:'center' }}
                                                        >
                                                            <tr
                                                            style={{
                                                                border: "1px solid #a3a3a3",
                                                            }}
                                                            >
                                                            <th
                                                                style={{
                                                                border: "1px solid #a3a3a3",
                                                                width: "10%",
                                                                }}
                                                            >
                                                                #
                                                            </th>
                                                            <th
                                                                style={{
                                                                border: "1px solid #a3a3a3",
                                                                width: "30%",
                                                                }}
                                                            >
                                                                Leave Type
                                                            </th>
                                                            <th
                                                                style={{
                                                                border: "1px solid #a3a3a3",
                                                                width: "30%",
                                                                }}
                                                            >
                                                                Taken Leave
                                                            </th>
                                                            <th
                                                                style={{
                                                                border: "1px solid #a3a3a3",
                                                                width: "30%",
                                                                }}
                                                            >
                                                                Total Available Till Now
                                                            </th>
                                                            </tr>
                                                            {leavestypelist.map((v, k) => {
                                                              var total_till_now =
                                                                Number(v.carryforward_cnt) +
                                                                Number(v.credit_cnt) -
                                                                Number(v.debit_cnt);
                                                            return (
                                                                <tr style={{ border: "1px solid #a3a3a3" }}>
                                                                <th style={{ border: "1px solid #a3a3a3" }}>
                                                                    {k + 1}
                                                                </th>
                                                                <th style={{ border: "1px solid #a3a3a3" }}>
                                                                    {v.name}
                                                                </th>
                                                                <th
                                                                    style={{
                                                                    border: "1px solid #a3a3a3",
                                                                    }}
                                                                >
                                                                    {Number(v.lop_cnt)}
                                                                </th>
                                                                <th
                                                                    style={{
                                                                    border: "1px solid #a3a3a3",
                                                                    }}
                                                                >
                                                                    {total_till_now > 0 ? total_till_now : 0}
                                                                </th>
                                                                </tr>
                                                            );
                                                            })}
                                                            <tr style={{ border: "1px solid #a3a3a3" }}>
                                                            <th style={{ border: "1px solid #a3a3a3" }}></th>
                                                            <th style={{ border: "1px solid #a3a3a3" }}>Total</th>
                                                            <th style={{ border: "1px solid #a3a3a3" }}>{debited_leaves}</th>
                                                            </tr>
                                                        </table>
                                                        </div>
                                                    </div>


                   
                                                    <table className="mt-4 table">
                                                       
                                                        <tbody>
                                                            <tr>
                                                                <td scope="row" colSpan="2" style={{ padding: '0px' }}>
                                                                    <table className='table  table-bordered' style={{ width: '100%' }}>
                                                                        <tr>
                                                                            <th style={{padding: '8px'  }} scope="col">Earnings</th>
                                                                            <th style={{padding: '8px',textAlign: 'center'  }} scope="col">Amount</th>
                                                                        </tr>
                                                                        {
                                                                           earning_head.map((val1)=>{

                                                                            var data = salaryMonth.find(val => val.typ == 'earning' && val.earning_name == val1.name)
                                                                            var ishead = selectedHeads.includes(val1.id.toString())

                                                                            return (
                                                                                data && ishead ? (
                                                                                    <tr style={{ borderBottom: '1px solid #dee2e6',padding: '7px'  }}>
                                                                                    <th style={{padding: '7px'  }}> {data.earning_name}</th>
                                                                                    <td style={{ textAlign: 'center',padding: '7px'  }}>{data.amount}</td>
                                                                                </tr>     
                                                                                    ) 
                                                                             : null
                                                                              
                                                                            )
                                                                          })
                                                                        }
                                                                        <tr className="border-top">
                                                                           <th scope="row">Total Earning</th>
                                                                           <th style={{textAlign: 'center'  }}>
                                                                            {earning_tot}
                                                                           </th>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                                <td colSpan="2" style={{ padding: '0px' }}>
                                                                    <table className='table  table-bordered' style={{ width: '100%' }}>
                                                                        <tr>
                                                                        <th style={{padding: '8px'  }} scope="col">Deductions</th>
                                                                        <th style={{padding: '8px',textAlign: 'center'  }} scope="col">Amount</th>
                                                                        </tr>
                                                                        {

                                                                            deduct_head.map((val1)=>{

                                                                                var data = salaryMonth.find(val => val.typ == 'deduction' && val.deduction_name == val1.name)
                                                                                var ishead = selectedHeadsDr.includes(val1.id.toString())

                                                                                return (
                                                                                    data && ishead? (
                                                                                        <tr style={{ borderBottom: '1px solid #dee2e6',padding: '7px'  }}>
                                                                                        <th style={{padding: '7px'  }}> {data.deduction_name}</th>
                                                                                        <td style={{ textAlign: 'center',padding: '7px'  }}>{data.amount}</td>
                                                                                    </tr>     
                                                                                        ) 
                                                                                : null
                                                                                
                                                                                )
                                                                            })
                                                                        }
                                                                         <tr className="border-top">
                                                                            <th>Total Deductions</th>
                                                                            <th style={{textAlign: 'center'  }}>{deduction_tot}</th>
                                                                        </tr>
                                                                    </table>
                                                                </td>

                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div style={{paddingTop: '5px',paddingBottom:'5px',fontSize: '18px'}}><b>Net Pay. : </b> {earning_tot-deduction_tot}</div>

                                               
                                            </div>
                                          
                                        </div>
                                        <div className="row" style={{textAlign:'center',padding:'8px',width: '100%'}}>
                                                    <div className="" style={{textAlign:'center',width:'100%',fontSize:'12px'}}>
                                                         <span className="mt-4">This is a System Generated Payslip, Hence Signature and Stamp does not Required</span>
                                                     </div>
                                          </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Salary_slip