import React, { useState, useEffect,useRef } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import Loadder from "./Loadder";
import { useAlert } from "react-alert";
import FullScreenLoading from 'react-fullscreen-loading';
import { confirmAlert } from "react-confirm-alert";

function Annual_salary_report() {

    const csvLink_annualSalary = useRef();
    const csvLink_annualSalarySummary = useRef();


    const [loader, setLoader] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('')
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [allchecked, setallchecked] = useState(null);
    const [selectedEmp, setSelectedEmp] = useState([]);
    const [dwnAnnualSalaryList, setDwnAnnualSalaryList] = useState([]);
    const [dwnAnnualSalarySummaryList, setDwnAnnualSalarySummaryList] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [salaryMonths, setSallaryMonths] = useState([]);
    const [empList, setEmpList] = useState([]);
    const [empStatus, setEmpStatus] = useState(1);

    useEffect(() => {loadData('50','1','',empStatus); getEmpCount("",empStatus);getMonthData()}, []);



    const getMonthData = () => {
        Apiconnect.getData(`employee_monthly_salary/getSalarySlipAllYears/${Apiconnect.get_company_id()}`).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           var monthsList=_xtract;
           setSallaryMonths(monthsList);
        });
     };

    const handlePageChange = (pageNumber) => {
      
        setActivePage(pageNumber);
        let offset = (pageNumber - 1) * 50 + 1;
        let limit = 50;
        loadData(limit,offset,'',empStatus);
      }

    const loadData = (limit,offset,search,status=null) => {
        setLoader(true);

        let info = { company_id: Apiconnect.get_company_id(), limit:limit,offset:offset,search:search,status:status};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        Apiconnect.postData(`employee/getallemployeelistreport`, inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            let newData = [];
            _xtract.map((item, index) => {
                newData.push({sr: index + 1, ...item});
            });
            setEmpList(newData);
            if(allchecked){
              var ob = {};
              newData.map((val, key) => {
                ob[val.id] = val.id;
              });
              setSelectedEmp({...selectedEmp,...ob});
            }
            setLoader(false);
        });

    }

    const [empCount, setEmpCount] = useState(0);

    const getEmpCount = (search,status) => {

        let info = { company_id: Apiconnect.get_company_id(),search:search,status:status};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };
        Apiconnect.postData(`employee/getallemployeelistreportcnt`,inf_a).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setEmpCount(_xtract[0].cnt); 
       });
    };
    
   

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          let offset = (activePage - 1) * 50;
          let limit = 50;
         
          loadData(limit, offset, searchTerm,empStatus);
          getEmpCount(searchTerm,empStatus)
        }, 2000)
      
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm])
      
      const customStyles = {
        content: {
           top: '50%',
           left: '55%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
           minWidth:'800px',
           maxHeight:'500px'
          
        },
     };


const getAnnualSalaryReport = () => {

    if (selectedEmp != "") {
      
     var selectedEmp1 = selectedEmp; 
      if(allchecked){
        selectedEmp1 = '99999All';
      }
      setFullScreenLoader(true)

      const inf_a = {yyyymm:selectedMonth, company_id : Apiconnect.get_company_id(), employee: selectedEmp1,status:empStatus };
      Apiconnect.postData("employee_salary/getAnnualSalaryReport", inf_a).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        if(_xtract){
          setDwnAnnualSalaryList(_xtract);
          setTimeout(function () {csvLink_annualSalary.current.link.click();}, 100);
          setFullScreenLoader(false)
        }
      });

    } else {
      confirmAlert({
        title: "Select Employee",
        buttons: [
          {
            label: "OK",
          },
        ],
      });
    }
  }

  const annualSalarySummary = (empid) => {
    setFullScreenLoader(true)  
    Apiconnect.getData(`employee_salary/getAnnualSalarySummaryReport/${Apiconnect.get_company_id()}/${selectedMonth}/${empid}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      if(_xtract){
        setDwnAnnualSalarySummaryList(_xtract);
        setTimeout(function () {csvLink_annualSalarySummary.current.link.click();}, 100);
        setFullScreenLoader(false)
      }
    });
    
  }


const sellectAll = (e) => {
    if (e.target.checked === true) {
      setallchecked("checked");
      let ob = {};
      empList.map((val, key) => {
        ob[val.id] = val.id;
      });
      setSelectedEmp(ob);
    } else {
      setallchecked(null);
      setSelectedEmp([]);
    }
  };

  const onInputChange2 = (e) => {
    setallchecked(null);

    if (e.target.checked === true) {
      setSelectedEmp({
        ...selectedEmp,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });
    }
  };

  const onChangeMonth = (e) => {

    var year = e.target.value;
    if(year != ''){
        setSelectedMonth(year)
    }else{
        setSelectedMonth('')
    }
   
 }


 const onSatusChange = (e) => {
  let status = e.target.value;
  setEmpStatus(status);
  let offset = (activePage - 1) * 50;
  let limit = 50;
  loadData(limit, offset, searchTerm,status);
  getEmpCount(searchTerm,status)
};

 const months = {'01' :'JAN','02' :'FEB','03' :'MAR','04' :'APR','05' :'MAY',
 '06' :'JUN','07' :'JUL','08' :'AUG','09' :'SEP','10' :'OCT','11' :'NOV','12' :'DEC'};


    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">

                {fullScreenLoader
            ? (
              <FullScreenLoading loading loaderColor="#3498db" />
            )
             
            : null
            }

            <CSVLink
            data={dwnAnnualSalaryList}
            filename={'AnnualSalary.csv'}
            className="hidden"
            ref={csvLink_annualSalary}
            target="_blank"
            /> 
            <CSVLink
            data={dwnAnnualSalarySummaryList}
            filename={'AnnualSalary.csv'}
            className="hidden"
            ref={csvLink_annualSalarySummary}
            target="_blank"
            /> 

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div>
                                    <h4 className="card-title hd">Annual Salary Report</h4>
                                </div>
                                
                                <div className="iq-card-body">

                                <div className="row p_t_15">
                       
                                <div className="col-md-3">
                                    Year : <br />
                                    <select className="form-control" name="Year" onChange={(e)=>onChangeMonth(e)}>
                                        <option value="">Select Year</option>
                                        {
                                        salaryMonths.map((value,key)=>{
                                        if(value.status == 1){
                                            return (
                                            <option value={value.year}>{value.year}</option>
                                        )
                                        }
                                            
                                        })
                                        }
                                        
                                    </select>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>Employee Status</label>
                                    <select
                                      className="form-control"
                                      required
                                      name="emp_status"
                                      value={empStatus}
                                      onChange={(e) => onSatusChange(e)}
                                    >
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                      <option value="">Both</option>
                                    </select>
                                  </div>
                                </div>  

                                </div>

                                    <div className="row" style={{ marginTop: '25px' }} >

                   <div style={{ padding: "10px", textAlign: "right" }}>
                    <div>
                      <input
                        type="text"
                        placeholder="Search here..."
                        name="search"
                        // onChange={(e) => inputsearch(e)}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                   <div style={{ padding: "10px",position: 'absolute',right: 0,marginRight: '20px' }}>                    
                    {
                        selectedMonth ?
                        (
                            <button style={{float:'right'}}  className="btn btn-info btn-sm" onClick={() => getAnnualSalaryReport()}>
                            <i className="fa fa-download"></i>
                            {` `} Annual Salary Report
                            </button>
                        )
                        : null 
                    }
                    </div>

                  <table className="table table-striped table-hover">
                    <thead className="">
                      <tr>
                      <th scope="col">
                                            <input
                                                type="checkbox"
                                                name="select_all"
                                                checked={allchecked}
                                                onChange={(e) => sellectAll(e)}
                                            />
                                          </th>
                        <th scope="col">#</th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Profile
                        </th>
                        <th scope="col">Emp Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Email</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {loader ? (
                        <Loadder />
                      ) : (
                        empList.map((val, key) => {
                          if (val.display_picture == null) {
                            var profile_img = "/images/user/1.jpg";
                          } else {
                            const min = 1;
                            const max = 1000;
                            var rand = min + Math.random() * (max - min);
                            var profile_img =
                              process.env.React_App_BASE_URL_API +
                              "/uploads/thumbdp/" +
                              val.display_picture +
                              "?" +
                              rand;
                          }

                          if (val.thumb == null) {
                            var atten_img = "";
                          } else {
                            const min = 1;
                            const max = 1000;
                            var rand = min + Math.random() * (max - min);
                            var atten_img =
                              process.env.React_App_BASE_URL_API +
                              "/uploads/thumb/" +
                              val.thumb +
                              "?" +
                              rand;
                          }

                          var ck = Object.keys(selectedEmp).find(function (
                            element
                        ) {
                            return selectedEmp[element] == val.id;
                        });

                        var ck1 = "";
                        if (ck !== undefined) {
                            ck1 = true;
                        }

                        
                          return (
                            <tr key={key}>
                                 <th scope="row">
                                                    <input
                                                    type="checkbox"
                                                    name={val.id}
                                                    value={val.id}
                                                    checked={ck1}
                                                    onChange={(e) => onInputChange2(e)}
                                                    
                                                    />
                                                </th>
                              <th scope="row">{key + 1}</th>
                              <td
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                <div className="" style={{ display: "grid" }}>
                                  <img
                                    src={profile_img}
                                    alt="profile-img"
                                    className="prof"
                                  />
                                </div>
                              </td>
                              <td>{val.emp_code}</td>
                              <td>
                                {val.first_name} {val.last_name}
                              </td>
                              <td>{val.phone}</td>
                              <td>{val.email}</td>
                              <td>
                                {
                                    selectedMonth ?
                                    (
                                     <button  className="btn btn-info btn-sm" onClick={() => annualSalarySummary(val.id)}>
                                        <i className="fa fa-download"></i>
                                        {` `} Annual Salary Summary
                                    </button>
                                    )
                                    : null
                                }
                                              
                                               </td>  
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  <div>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={50}
                      totalItemsCount={empCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )

}
export default Annual_salary_report