import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import "./ems_pages.css";
import "./userstyle.css";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "./Calendar.js";
import { Link } from 'react-router-dom';

function Dashboard() {

   const alert = useAlert();
   let history = useHistory();
   const localuser = JSON.parse(localStorage.getItem('user'));
   let emp = Apiconnect.decrypt(localuser.id);
   let username = localuser.fullname;

   console.log('localuser',localuser)



   useEffect(() => { getHolidayList(); getEmployeeList(); getEmployeeBirthList(); getEmployeeUpcommingBirthList();getprofile(); getEmployeeLeaveList(); }, []);
   const [holidayList, setHolidayList] = useState([]);
   const [employeeList, setEmplooyeeeList] = useState([]);
   const [employeeBirthList, setEmplooyeeeBirthList] = useState([]);
   const [employeeUpcommingBirthList, setEmplooyeeeUpcommingBirthList] = useState([]);
   const [employeeLeaveList, setEmplooyeeLeaveList] = useState([]);
   const [profile_image, setprofile_image] = useState(null);
   
   const getprofile = () => {  
      Apiconnect.getData(`employee/get/${emp}`).then((response) => {        
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         if(_xtract[0].thumb == null){
            setprofile_image('/images/user/1.jpg'); 
         }else{
            var img = process.env.React_App_BASE_URL_API+'/uploads/thumbdp/'+_xtract[0].display_picture;
            setprofile_image(img); 
         }
      });
    };

   const getHolidayList = () => {
      Apiconnect.getData(`company_holiday/getdata`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setHolidayList(_xtract.reverse());
      });

   }

   const getEmployeeList = () => {
      Apiconnect.getData(`employee/getdata`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setEmplooyeeeList(_xtract.reverse());
      });

   }

   const getEmployeeBirthList = () => {
      Apiconnect.getData(`employee/getbirthdata`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setEmplooyeeeBirthList(_xtract.reverse());
      });

   }

   const getEmployeeUpcommingBirthList = () => {
      Apiconnect.getData(`employee/getupcommingbirthdata`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setEmplooyeeeUpcommingBirthList(_xtract.reverse());
      });

   }

   const getEmployeeLeaveList = () => {
      Apiconnect.getData(`employee_leave/getdatabyid/${emp}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setEmplooyeeLeaveList(_xtract.reverse());
      });

   }

   const editProfile = e => {
      history.push("/ems/editProfile");
   }

   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">





               <div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div className="iq-card-body profile-page p-0">
                           <div className="profile-header">
                              <div className="cover-container myimg" >

                                 <ul className="header-nav d-flex flex-wrap justify-end p-0 m-0">
                                    {
                                       //<li><a href=""><i className="ri-pencil-line"></i></a></li>
                                       //<li><a href=""><i className="ri-settings-4-line"></i></a></li>
                                    }

                                 </ul>
                              </div>
                              <div className="profile-info p-4">
                                 <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                       <div className="user-detail pl-5">
                                          <div className="d-flex flex-wrap align-items-center">
                                             <div className="profile-img pr-4" style={{display:'grid'}}>
                                                <img src={profile_image} alt="profile-img" className="avatar-130" />
                                                <Link to={`/ems/employee_upload_image`} style={{paddingLeft: '15px'}}>Change Profile</Link>
                                                {/* <a style={{paddingLeft: '15px'}} href="#">Change Profile</a> */}
                                             </div>
                                             <div className="profile-detail d-flex align-items-center">
                                                <h3>{username}</h3>
                                                {/* <p className="m-0 pl-3"> - Account Head</p> */}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                       <ul className="nav nav-pills d-flex align-items-end float-right profile-feed-items p-0 m-0">

                                          <li>
                                             <botton data-toggle="pill" className="nav-link active" style={{ cursor: 'pointer' }} onClick={e => editProfile(e)}>Edit Profile</botton>
                                             {/* <a className="nav-link active" data-toggle="pill" href="#profile-feed">Edit Profile</a> */}
                                          </li>

                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               
               
               
               <div className="row " style={{'margin-bottom': '20px',}}>
                 
                  <div className="col-lg-8">
                  {<Calendar />}
                  </div>

                  

               </div>


               <div className="row ">
               <div className="col-lg-4">
                     <div className="iq-card">
                        <img src="../images/small/holiday.jpg" className="img-fluid w-100 rounded" alt="#" />
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Upcoming Holidays</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <ul class="media-story m-0 p-0">
                              {holidayList.map((val, key) => {
                                 return (

                                    <li key={key} class="d-flex mb-4 align-items-center active">
                                       <img src="../images/page-img/s1.jpg" alt="story-img" class="rounded-circle img-fluid" />
                                       <div class="stories-data ml-3">
                                          <h5>{val.name}</h5>
                                          <p class="mb-0">{val.start_date}</p>
                                       </div>
                                    </li>


                                 );
                              })}
                           </ul>

                        </div>
                     </div>
                  </div>



                  <div className="col-lg-4">
                     <div className="iq-card">
                        <img src="../images/small/leave.jpg" className="img-fluid w-100 rounded" alt="#" />
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Leave Status</h4>
                           </div>
                        </div>




                        <div class="iq-card-body">



                           {employeeLeaveList.map((val, key) => {

                              if (val.status == 0) {
                                 var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                              }
                              if (val.status == 1) {
                                 var status = <div className=" badge badge-pill badge-success">Approved</div>;
                              }
                              if (val.status == 2) {
                                 var status = <div className=" badge badge-pill badge-danger">Cancelled</div>;
                              }

                              return (
                                 <>
                                    <div class="media-support">
                                       <div class="media-support-header mb-2">
                                          <div class="media-support-user-img mr-3">
                                             <img class="rounded-circle" src="../images/user/1.jpg" alt="" />
                                          </div>
                                          <div class="media-support-info mt-2">
                                             <h6 class="mb-0"><a href="#" class=""><strong>{val.name}</strong> for {val.leave_start_date2}</a></h6>
                                             <small>Applied On {val.applied_on}</small>
                                          </div>
                                          <div class="mt-3">
                                             {status}
                                          </div>
                                       </div>
                                       <div class="media-support-body">
                                          <p class="mb-0">{val.reason}</p>
                                       </div>
                                    </div>
                                    <hr class="mt-4 mb-4" />
                                 </>
                              );
                           })}
                        </div>

                     </div>
                  </div>


                  <div className="col-lg-4">
                     <div className="iq-card">
                        <img src="../images/small/party.jpg" className="img-fluid w-100 rounded" alt="#" />
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Activities</h4>
                           </div>
                        </div>




                        <div class="iq-card-body">
                           <ul class="iq-timeline">




                              {employeeList.map((val, key) => {
                                 return (

                                    <li>
                                       <div class="timeline-dots border-primary"></div>
                                       <h6 class="float-left mb-1"><strong>New Joining</strong></h6>
                                       <small class="float-right mt-1">{val.joining_date1}</small>
                                       <div class="d-inline-block w-100">
                                          <p>Mr/Mis {val.first_name} {val.last_name} joined to the team. Say congrats!!</p>
                                       </div>
                                    </li>


                                 );
                              })}

                              {employeeBirthList.map((val, key) => {
                                 return (

                                    <li>
                                       <div class="timeline-dots border-success"></div>
                                       <h6 class="float-left mb-1"><strong>Birthday Alert</strong></h6>
                                       <small class="float-right mt-1">{val.date_of_birth1}</small>
                                       <div class="d-inline-block w-100">
                                          <p>{val.first_name} {val.last_name} has birthday today. Share your wish.</p>
                                       </div>
                                    </li>


                                 );
                              })}

                              {employeeUpcommingBirthList.map((val, key) => {
                                 return (

                                    <li>
                                       <div class="timeline-dots border-success"></div>
                                       <h6 class="float-left mb-1"><strong>Upcoming Birthday</strong></h6>
                                       <small class="float-right mt-1">{val.date_of_birth1}</small>
                                       <div class="d-inline-block w-100">
                                          <p>{val.first_name} {val.last_name}'s birthday falls on {val.date_of_birth2}. Plan a surprise.</p>
                                       </div>
                                    </li>


                                 );
                              })}

                           </ul>

                        </div>
                     </div>
                  </div>




               </div>







               <div className="row gap">

               </div>






































               {/* <div className="row">

                  <div className="col-sm-6">
                     <div className="iq-card">
                        <div className="iq-card-body">
                           <div className="row justify-content-between">
                              <div className="col-sm-12 col-md-6">
                              </div>
                              <div className="col-sm-12 col-md-6">
                                 <div className="user-list-files d-flex float-right hd2">
                                    Leave Status
                                 </div>
                              </div>
                           </div>
                           <table id="user-list-table" className="table table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                              <thead>
                                 <tr className="clr">
                                    <th>Sr.No.</th>
                                    <th>Name</th>
                                    <th>Opening</th>
                                    <th>Available</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td>1</td>
                                    <td></td>
                                    <td>XYZ</td>
                                    <td>XYZ</td>
                                 </tr>

                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                  <div className="col-sm-6">
                     <div className="iq-card">
                        <div className="iq-card-body">
                           <div className="row justify-content-between">
                              <div className="col-sm-12 col-md-6">
                              </div>
                              <div className="col-sm-12 col-md-6">
                                 <div className="user-list-files d-flex float-right hd2">
                                    Public Holiday's
                                 </div>
                              </div>
                           </div>
                           <table id="user-list-table" className="table table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                              <thead>
                                 <tr className="clr">
                                    <th>Sr.No.</th>
                                    <th>Date</th>
                                    <th>Public Holiday</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td className="">1</td>
                                    <td>05/07/2021</td>
                                    <td>XYZ</td>
                                 </tr>

                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>

               </div>


               <div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card">
                        <div className="hd3">
                           <h4 className="card-title head1">Today Birthday</h4>
                        </div>
                        <div className="iq-card-body">
                           <div className="table-responsive">

                              <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                 <thead>
                                    <tr className="clr">
                                       <th>Code</th>
                                       <th>Name</th>
                                       <th>Department</th>
                                       <th>Location</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td>1</td>
                                       <td>Anna Sthesia</td>
                                       <td>pune</td>
                                       <td>USA</td>
                                    </tr>

                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}

               {/* <div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card">

                        <div className="hd4" >
                           <h4 className="card-title head1">Today Join Employees</h4>
                        </div>
                        <div className="iq-card-body">
                           <div className="table-responsive">

                              <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                 <thead>
                                    <tr className="clr">
                                       <th>Code</th>
                                       <th>Name</th>
                                       <th>Department</th>
                                       <th>Location</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td>1</td>
                                       <td>Anna Sthesia</td>
                                       <td>pune</td>
                                       <td>USA</td>
                                    </tr>

                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}

            </div>
         </div>



      </>
   )
}

export default Dashboard
